
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser,mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import {maskPrice} from '../../Auxiliar/Masks';

class EditarProduto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/dados/1',
            loading_screen: true,
            name: '',
            marca: '',
            unidade: '',
            loading_save: false,
            msgErro: '',
            preco_antes:'',
            loja:{},
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('produto_id', this.props.match.params.id);
        form.append('name', this.state.name);
        form.append('marca', this.state.marca);
        form.append('unidade', this.state.unidade);
        form.append('preco_antes', this.state.preco_antes.replace('R', '').replace('$', '').replace(' ', '').replace(',', '.'));

        fetch(`${URL}api/cliente/editar_produto`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Modelos');

        this.get_produto(this.props.match.params.id);
    }

    get_produto(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/cliente/get_produto/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_screen: false,
                        name:resp.produto.name,
                        unidade:resp.produto.unidade==null?'':resp.produto.unidade,
                        marca:resp.produto.marca==null?'':resp.produto.marca,

                        preco_antes:'R$ ' +resp.produto.preco_antes.replace('.',','),
                        loja: resp.loja, 
                    });
                    // if (resp.processo != null) {
                        this.props.mudarTitle('Produtos - '+resp.loja.name);


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Produto</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um produto do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro!='' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{marginBottom:0,whiteSpace:'pre'}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome</label>
                                            <div className="col-sm-12">
                                                <input value={this.state.name} className="form-control" placeholder="Nome do produto" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Marca</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Marca" type="text" value={this.state.marca} onChange={(e) => this.setState({ marca: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Unidade</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Unidade" type="text" value={this.state.unidade} onChange={(e) => this.setState({ unidade: e.target.value })} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Preço</label>
                                            <div className="col-sm-12">
                                                <input value={this.state.preco_antes} className="form-control" placeholder="Preço" type="text" onChange={(e) => this.setState({ preco_antes: maskPrice(e.target.value) })} />

                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to={"/dados/1"}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar produto</button>
                                    </div>
                                </div>}
                               
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarTitle })(EditarProduto);


