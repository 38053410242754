
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment-timezone';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { maskPrice } from '../../Auxiliar/Masks';

class Banners extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            banners: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            banner_to_delete: '',
            loja: {},
            search: '',
            search_oficial: '',
            loading_banners: false

        };
    }






    componentDidMount() {

        this.get_banners(this.state.page, this.props.loja_id, this.state.search_oficial);
    }

    get_banners(page, id, search, busca = false) {
        if (busca == true) {
            if (this.state.loading_banners == false) {
                this.setState({ loading_banners: true });
            }
        }
        else if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        let params = '';
        if (search != '') {
            params = `&id=${id}&search=${search}`;
        }
        else {
            params = `&id=${id}`;
        }
        fetch(`${URL}api/cliente/get_banners?page=${page}${params}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if (search == this.state.search) {
                        this.setState({ loading_banners: false, banners: resp.banners, loja: resp.loja, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    }
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_banner(id) {

        fetch(`${URL}api/cliente/delete_banner/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_banners(this.state.page, this.props.loja_id, this.state.search_oficial);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <br />


                    {/* <hr /> */}
                    {this.state.loading_screen == false && <div>
                        <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Banners</h4>
                        <div className="row mb-2">
                            <div className="col-12 col-md-6">
                                <form className="app-search" style={{ width: '100%' }} onSubmit={(e) => {
                                    clearTimeout(this.pesquisarTimeout);
                                    this.setState({ page: 1,search_oficial:this.state.search });
                                    this.get_banners(1, this.props.loja_id, this.state.search, true)
                                    e.preventDefault();
                                }}>
                                    <div className="form-group mb-0" style={{ width: '100%' }}>
                                        <input value={this.state.search} name="search" placeholder="Pesquisar..." className="form-control" style={{ width: '100%' }} onChange={(e) => {
                                            clearTimeout(this.pesquisarTimeout);
                                            let search = e.target.value;
                                            this.setState({ search: search });
                                            this.pesquisarTimeout = setTimeout(()=>{
                                                this.setState({ page: 1,search_oficial:search });
                                                this.get_banners(1, this.props.loja_id, search, true)
                                            },500);
                                        }} />
                                        <button type="submit"><i className="fa fa-search"></i></button>

                                    </div>
                                </form>
                                {this.state.search_oficial != '' && <span>Buscando por banners: {this.state.search_oficial}</span>}

                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-end">
                                <Link to={"/"}><span
                                    className="btn btn-primary btn-lg waves-effect waves-light" style={{ height: '45px' }}>Adicionar banner</span></Link>
                            </div>
                        </div>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <table id="tech-companies-1" className="table table-striped">
                                    <thead>
                                        <tr>

                                            <th>Nome</th>
                                            <th>Criado por</th>
                                            <th>Folha</th>
                                            <th>Data de atualização</th>


                                            <th data-priority="3">Ações</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.loading_banners == false && this.state.banners.map((item, id) => (

                                            <tr key={id}>

                                                <th>{item.name}</th>
                                                <td>{item.user}</td>
                                                <td>{item.folha}</td>
                                                <td>{moment.utc(item.data,'DD/MM/YYYY HH:mm').tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm')}</td>

                                                {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={URL + 'storage/' + URL + 'storage/' + item.arquivo} /></td> */}

                                                <td>
                                                    <Link to={'/banner_pdf_cliente/' + item.id} target='_blank'><button
                                                        className="destroy_it btn btn-success btn-outline btn-circle m-r-5"><i
                                                            className="fas fa-print"></i></button></Link>
                                                    <Link to={'/model_banner/' + item.id} ><button
                                                        className="destroy_it btn btn-info btn-outline btn-circle m-r-5"><i
                                                            className="fas fa-edit"></i></button></Link>
                                                    <button onClick={() => {
                                                        this.setState({ show_warning: true, banner_to_delete: item.id });
                                                    }}
                                                        className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                            className="ti-trash"></i></button>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                                {this.state.loading_banners == true &&
                                    <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                        <div className="spinner-border text-primary" role="status"  >
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div></div>

                                }
                            </div>
                        </div>
                        {this.state.banners.length == 0 && <div className="row w-100">
                            <div className="col-12">
                                <p style={{ textAlign: 'center' }}>Nenhum banner cadastrado</p>

                            </div>
                        </div>}
                        {this.state.loading_banners == false && <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <ReactPaginate
                                    previousLabel={'Anterior'}
                                    nextLabel={'Próxima'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.total_pages}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handlePageClick.bind(this)}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    initialPage={this.state.page - 1}
                                />
                            </div>
                        </div>}



                        {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                    </div>}

                    {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div>}
                    <SweetAlert
                        warning
                        title={"Atenção"}
                        onConfirm={() => {
                            this.delete_banner(this.state.banner_to_delete);
                        }}
                        onCancel={() => {
                            this.setState({ show_warning: false });
                        }}
                        show={this.state.show_warning}
                        confirmBtnText='Sim, desejo apagar'
                        cancelBtnText='Cancelar'
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Ao deletar este banner ele não estará mais disponível no sistema

                                </SweetAlert>
                    <SweetAlert
                        danger
                        title={"Erro"}
                        onConfirm={() => {
                            this.setState({ show_erro: false });
                        }}
                        show={this.state.show_erro}
                        confirmBtnText='OK'
                        confirmBtnBsStyle="primary"

                    >
                        {this.state.msgErro}

                    </SweetAlert>

                </div>

            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_banners(page.selected + 1, this.props.loja_id, this.state.search_oficial);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Banners));


