
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser } from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../assets/images/users/user-4.jpg';
import moment from 'moment';
import { URL } from '../variables';

class PageTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        return (
            <div className="page-title-box">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <h4 style={{ color: '#870000', textAlign: 'center' }}>{this.props.title}</h4>
                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        title: state.AppReducer.title,
    }
);

export default connect(mapsStateToProps, {})(PageTitle);


