
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../../../../variables';
import ReactPaginate from 'react-paginate';
import { maskPrice } from '../../../Auxiliar/Masks';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Banner from './Banner';
import { jsPDF } from 'jspdf';
import '../../../../assets/fontes/arial-black-normal';
import { formatMs } from '@material-ui/core';
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
class ProdutosCliente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            produtos: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            produto_to_delete: '',
            loja: {},
            loading_lista: false,
            lista_modal: false,
            csv: '',
            produtos_csv: [],
            modelo_id: '',
            folha_id: '',
            banners: [],
            modelos: [],
            folhas: [],
            loading:false
        };
        this.dados = {}
        this.width = 100;
    }


    salvar(name) {
        let data = {
            name,
            folha: this.state.folhas[this.state.folha_id] != undefined ? this.state.folhas[this.state.folha_id] : '',
            modelo_id: this.state.modelo_id,
            banners: [],
            loja_id:this.props.match.params.loja_id
        }
        // form.append('produto_id', this.state.produto.id);
        let dados_ = Object.values(this.dados);
        for (let j = 0; j < dados_.length; j++) {
            let dados_banner = [];
            for (let i = 0; i < dados_[j].length; i++) {
                let dados = { ...dados_[j][i] };
                dados.value = dados.ref.current.textContent;
                dados.height = dados.height / 599;
                dados.width = dados.width / this.width;
                dados.x = dados.x / this.width;
                dados.y = dados.y / 599;
                delete dados.ref;
                delete dados.refInput;
                dados_banner.push(dados);
            }
            data.banners.push(dados_banner);
        }

        fetch(`${URL}api/salvar_lista_csv`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                // console.log('/link/' + resp.banner.arquivo.replace(/\//g, '-'));
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro });
                }
                else {
                    // var urlCreator = window.URL || window.webkitURL;
                    // var imageUrl = urlCreator.createObjectURL(arquivo);
                    // this.setState({ loading_save: false });
                    

                    this.setState({ loading: false });
                    // setTimeout(() => {
                    //     var linkToClick = document.getElementById('gerar');
                    //     linkToClick.click();
                    // }, 500);

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    get_data(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_lista_csv_data/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);

                    this.setState({
                        loading_screen: false,
                        modelos: resp.modelos,
                        folhas: resp.folhas
                    });


                }




            } catch (err) {
                // this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                // this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    componentDidMount() {
        this.get_data(this.props.match.params.loja_id);
        // this.get_produtos(this.state.page, this.props.loja_id);
    }

 
  

    

    change_folha(event) {
        this.setState({ folha_id: event.target.value });

    }

    change_modelo(event) {
        this.setState({ modelo_id: event.target.value });
    }

    async gerar_pdf() {
        this.setState({loading:true});
        var doc = new jsPDF('p', 'px', this.state.folhas[this.state.folha_id].toLowerCase())
        let page = doc.getPageInfo(1);
        console.log(doc.internal.pageSize.height);
        console.log(doc.internal.pageSize.width);
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        doc.setFont('arial-black', 'normal');
        let modelo = {}
        for (let i = 0; i < this.state.modelos.length; i++) {
            if (this.state.modelos[i].id == this.state.modelo_id) {
                modelo = this.state.modelos[i];
                break;
            }
        }
        let dados_ = Object.values(this.dados);
        console.log(dados_);
        let html = '';

        for (let j = 0; j < dados_.length; j++) {

            if (j != 0) {
                await doc.addPage();
            }
            // await doc.addImage(modelo.arquivo, 'JPEG', 0, 0, width, height);


            console.log(doc.getFontList());
            for (let i = 0; i < dados_[j].length; i++) {
                let dados = { ...dados_[j][i] };
                // dados.value = dados.ref.current.textContent;

                console.log(((dados.height / 599)), ' ', dados.value);
                console.log('fontsize: ', ((dados.height / 599).toPrecision(80) * doc.internal.pageSize.height))
                console.log('Height:', (dados.height / 599) * doc.internal.pageSize.height);
                console.log('Rotacao:', dados.rotate);
                html += '<div  style="line-height: 1.5;padding-bottom:20%;margin:0;display:flex;justify-content:center;align-items:flex-start;border:0; font-family:\'helvetica\';font-weight:bold;left:' + parseFloat(dados.x / this.width) * width + 'px;width:' + (dados.width / this.width) * width + 'px;height:' + (dados.height / 599) * height + 'px;color:black;text-align:center;position:absolute;top:' + (parseFloat(dados.y / 599) * height + height * j) + 'px;font-size:' + Math.round(parseInt(parseFloat((dados.height / 599)) * height / 1.55)).toFixed(0) + 'px">' + dados.value + '</div>';
            }

            console.log(html);

            // doc.save('teste.pdf');

            // return;

        }
        await doc.html(html, { x: 0, y: 0 });
        let name = 'banners-' + moment().format('YYYY-MM-DD-HH-mm-ss') + '.pdf';
        doc.save(name);
        this.salvar(name);
    }

    render() {
        console.log(this.state.banners);

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <br />


                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Banners CSV</h4>
                                {this.state.loading == false && <div className="row mb-2">
                                    <div className="col-12 col-md-6 d-flex">
                                        {this.state.banners.length > 0 && <button type="button" onClick={(e) => {
                                            // this.setState({ lista_modal: true });
                                            this.gerar_pdf();
                                        }} className="btn btn-success btn-lg waves-effect waves-light" style={{ height: '45px', marginRight: 5 }}><span
                                        >Gerar PDF</span></button>}
                                        <button type="button" onClick={() => {
                                            this.dados = {};
                                            this.setState({ banners: [] });

                                        }} className="btn btn-danger btn-lg waves-effect waves-light"
                                        >
                                            <i className="fas fa-trash" style={{ color: 'white', marginRight: 10 }}></i>
                                                Limpar Lista</button>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-end">
                                        <Link to={"/ver_loja/"+this.props.match.params.loja_id+"/5"}> <span style={{ height: '45px', }}
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                        <button type="button" onClick={(e) => {
                                            this.setState({ lista_modal: true });
                                        }} className="btn btn-primary btn-lg waves-effect waves-light" style={{ height: '45px', marginLeft: 5 }}><span
                                        >Adicionar csv</span></button>
                                    </div>
                                </div>}
                                {this.state.loading == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}
                                {this.state.banners.map((item, id) => (
                                    <Banner modelos={this.state.modelos} folhas={this.state.folhas} key={id} index={id} dados={this.dados[id]} modelo_id={this.state.modelo_id} change_dados={(dados, width) => { this.dados[id] = dados; this.width = width }} produto={item} folha_id={this.state.folha_id} remover={() => {
                                        delete this.dados[id];
                                        let banners = this.state.banners.filter((item, index) => (
                                            index != id
                                        ));
                                        let banners_ = [];
                                        for (let i = 0; i < banners.length; i++) {
                                            banners_.push({ ...banners[i] });
                                        }
                                        this.setState({ banners: banners_ });
                                    }} />
                                ))}


                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    this.delete_produto(this.state.produto_to_delete);
                                }}
                                onCancel={() => {
                                    this.setState({ show_warning: false });
                                }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao deletar esta produto ele não estará mais disponível no sistema

                                </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({ show_erro: false });
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgErro}

                            </SweetAlert>

                        </div>
                        <Modal show={this.state.lista_modal} onHide={() => {
                            this.setState({ lista_modal: false })
                        }} >
                            <Modal.Header closeButton><Modal.Title>Adicionar csv</Modal.Title></Modal.Header>
                            <Modal.Body>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Arquivo CSV*</label>
                                    <div className="col-sm-12">
                                        <input className="form-control" accept="text/csv" placeholder="CsV" type="file" onChange={(e) => {
                                            this.setState({ csv: e.target.files[0] });
                                            var file = e.target.files[0]
                                            let reader = new FileReader()
                                            reader.readAsText(file)
                                            reader.onload = () => {
                                                let lines = reader.result.split("\n");
                                                console.log(lines);
                                                let i = 0;
                                                if (lines[0] != undefined && lines[0].toLowerCase().includes('nome')) {
                                                    i = i + 1;
                                                }
                                                let produtos = [];
                                                while (i < lines.length) {
                                                    let dados = lines[i].split(';');
                                                    i++;

                                                    if (dados[0] != '') {
                                                        produtos.push({ name: dados[0],marca:dados[1],unidade:dados[2], preco_antes: parseFloat(dados[3].replace(',', '.')).toFixed(2) })
                                                    }
                                                    else {
                                                        continue;
                                                    }
                                                }
                                                this.setState({ produtos_csv: produtos });

                                            };
                                            reader.onerror = function (error) {
                                                console.log('Error: ', error);
                                            }
                                        }} />

                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Folha</label>
                                    <div className="col-sm-12">
                                        <select value={this.state.folha_id} className="custom-select" id="cameras" onChange={this.change_folha.bind(this)}>
                                            <option value={''}>Selecione um tamanho de folha</option>

                                            {this.state.folhas.map((item, id) => (
                                                <option key={id} value={id}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Modelo</label>
                                    <div className="col-sm-12">
                                        <select value={this.state.modelo_id} className="custom-select" id="cameras" onChange={this.change_modelo.bind(this)}>
                                            <option value={''}>Selecione um modelo</option>

                                            {this.state.modelos.map((item, id) => (
                                                <option key={id} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {this.state.produtos_csv.length > 0 && <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped">
                                            <thead>
                                                <tr>

                                                <th>Produto</th>
                                                    {/* <th>Preço Promoção</th> */}
                                                    <th data-priority="1">Preço</th>
                                                    <th data-priority="1">Marca</th>
                                                    <th data-priority="1">Unidade</th>

                                                    <th data-priority="3">Ações</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.produtos_csv.map((item, id) => (

                                                    <tr key={id}>

<th>{item.name}</th>
                                                        {/* <td>{maskPrice(item.preco_promocao)}</td> */}

                                                        <td>{maskPrice(item.preco_antes)}</td>
                                                        <th>{item.marca}</th>
                                                        <th>{item.unidade}</th>

                                                        <td>

                                                            <button onClick={() => {
                                                                let produtos_csv = [...this.state.produtos_csv];
                                                                produtos_csv = produtos_csv.filter((item, index) => index != id);
                                                                this.setState({ produtos_csv });
                                                            }}
                                                                className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                                    className="ti-trash"></i></button>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>}

                            </Modal.Body>
                            <Modal.Footer>

                                {this.state.loading_lista == false && this.state.produtos_csv.length > 0 && <Button className="btn-success btn-lg" color="success" onClick={() => {
                                    if (this.state.modelo_id == '' || this.state.modelo_id == undefined) {
                                        return;
                                    }

                                    if (this.state.folha_id == '' || this.state.folha_id == undefined) {
                                        return;
                                    }
                                    this.dados = {};
                                    for (let i = 0; i < this.state.produtos_csv.length; i++) {
                                        this.dados[i] = [];
                                    }
                                    this.setState({ banners: this.state.produtos_csv, lista_modal: false, produtos_csv: [] });

                                    // else {
                                    //     this.setState({ msgErroLista: 'Selecione um csv para salvar os produtos' });
                                    // }
                                }}>Criar Banners</Button>}{' '}
                                {this.state.loading_lista == false && <Button className="btn-lg" color="secondary" onClick={() => {
                                    this.setState({ lista_modal: false, produtos_csv: [], csv: '' })
                                }}>Fechar</Button>}
                                {this.state.loading_lista == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

            </div>
        );
    }

   
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        modelos: state.ClienteReducer.modelos,
        folhas: state.ClienteReducer.folhas,

    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(ProdutosCliente));


