import React from 'react';
import Routes from './routes';
import {useSelector, useDispatch} from 'react-redux'


function App()  {
    const dispatch=useDispatch();
    let token = localStorage.getItem('token');
    dispatch({type:'login',payload:{token:token,user:{}}});

    return (
        <Routes />
    );
}

export default App;