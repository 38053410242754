export const login = (data) => (
    {
        type: 'login',
        payload: data
    }
);

export const logout = () => (
    {
        type: 'login',
        payload: ''
    }
);



export const mudarUser = (data) => (
    {
        type: 'user',
        payload: data
    }
);


export const mudarTitle = (data) => (
    {
        type: 'title',
        payload: data
    }
);
