import React from "react";
import ReactDOM from "react-dom";
import { URL } from '../../variables';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    BlobProvider,
    Document,
    Page,
    Text,
    View,
    Font,
    Image,
    Link,
    PDFViewer,
    StyleSheet
} from "@react-pdf/renderer";
import { logout, mudarUser, mudarTitle } from '../../actions/AppActions';

import "../../new_style.css";
Font.register({ family: 'Arial', src: require('../../assets/fontes/arial.ttf') });
Font.register({ family: 'Arial Black', src: require('../../assets/fontes/arial-black.ttf') });
Font.register({ family: 'Georgia', src: require('../../assets/fontes/georgia-4.ttf') });
Font.register({ family: 'Times New Roman', src: require('../../assets/fontes/times-new-roman-14.ttf') });
Font.register({ family: 'Comic Sans MS', src: require('../../assets/fontes/comic-sans-ms-4.ttf') });
Font.register({ family: 'Impact', src: require('../../assets/fontes/impact.ttf') });

Font.registerHyphenationCallback(word => (
    [word]
  ));


const PAGE_SIZES = {
    '4A0': [4767.87, 6740.79],
    '2A0': [3370.39, 4767.87],
    A0: [2383.94, 3370.39],
    A1: [1683.78, 2383.94],
    A2: [1190.55, 1683.78],
    A3: [841.89, 1190.55],
    A4: [595.28, 841.89],
    A5: [419.53, 595.28],
    A6: [297.64, 419.53],
    A7: [209.76, 297.64],
    A8: [147.4, 209.76],
    A9: [104.88, 147.4],
    A10: [73.7, 104.88],
    B0: [2834.65, 4008.19],
    B1: [2004.09, 2834.65],
    B2: [1417.32, 2004.09],
    B3: [1000.63, 1417.32],
    B4: [708.66, 1000.63],
    B5: [498.9, 708.66],
    B6: [354.33, 498.9],
    B7: [249.45, 354.33],
    B8: [175.75, 249.45],
    B9: [124.72, 175.75],
    B10: [87.87, 124.72],
    C0: [2599.37, 3676.54],
    C1: [1836.85, 2599.37],
    C2: [1298.27, 1836.85],
    C3: [918.43, 1298.27],
    C4: [649.13, 918.43],
    C5: [459.21, 649.13],
    C6: [323.15, 459.21],
    C7: [229.61, 323.15],
    C8: [161.57, 229.61],
    C9: [113.39, 161.57],
    C10: [79.37, 113.39],
    RA0: [2437.8, 3458.27],
    RA1: [1729.13, 2437.8],
    RA2: [1218.9, 1729.13],
    RA3: [864.57, 1218.9],
    RA4: [609.45, 864.57],
    SRA0: [2551.18, 3628.35],
    SRA1: [1814.17, 2551.18],
    SRA2: [1275.59, 1814.17],
    SRA3: [907.09, 1275.59],
    SRA4: [637.8, 907.09],
    EXECUTIVE: [521.86, 756.0],
    FOLIO: [612.0, 936.0],
    LEGAL: [612.0, 1008.0],
    LETTER: [612.0, 792.0],
    TABLOID: [792.0, 1224.0],
};
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        width: '100%',
        orientation: 'portrait',

    },
    view: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },
    section: {
        margin: 10,
        padding: 10,
    }
});
class Pdf extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            link: '',
            loading_screen: true,
            banner: {dados:[]},
        }

    }
    componentDidMount() {
        this.get_banner_link(this.props.match.params.link);
    }

    get_banner_link(link) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/cliente/get_banner_link/${link}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    if (resp.banner != null) {
                        this.setState({ banner: resp.banner ,loading_screen: false});
                    }
                    else {
                        this.setState({ loading_screen: false });
                    }
                    // window.onafterprint = (event) => {
                    //     console.log('After print');
                    //     console.log(this.state.banner);
                    //   };
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    render() {
        return (
            <div>
                {this.state.loading_screen == false && this.state.banner==null && <div className="row"><div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '5rem' }}><h3>Este banner não existe. Confira se o link está correto e tente novamente.</h3> </div></div>}

                {this.state.loading_screen == false && this.state.banner!=null && <PDFViewer style={{ height: '100vh', width: '100%' }}>
                    <Document>

                        <Page style={styles.page} size={this.state.banner.folha} verticalRulerSteps={100}>
                            <View style={styles.view}>
                                {/* <Text style={{
                                    color: "#000000",
                                    fontFamily: "Arial Black",
                                    fontSize: 61,
                                    maxHeight: parseInt(92.42243739565976),
                                    left: parseInt(16.117536723163756),
                                    position: "absolute",
                                    textAlign: "center",
                                    top: 0,
                                    value: "Leite Moça 1 real",
                                    backgroundColor:'blue',
                                    maxWidth: parseInt(369.75525423728817)
                                }}> das das das das dasdsa das d asd asd asd sad as dsad sad sad sad asd sad asd asd as das dasd as das das d asd asd asd asd asd asd  d asdas</Text> */}
                                {/* <Image style={{ width: '100%', height: '100%' }} src={'https://clickback.appgondola.com.br/storage/modelos/modelo_5f9102349a655.jpeg'} /> */}

                                {this.state.banner.dados.map((item, id) => {
                                    // console.log({ value: item.value, fontFamily: item.fontFamily, fontWeight: item.fontWeight, fontSize: parseInt(parseFloat(item.height) * PAGE_SIZES[this.state.banner.folha][1] / 1.5), textAlign: item.textAlign, width: parseFloat(item.width) * PAGE_SIZES[this.state.banner.folha][0], height: parseFloat(item.height) * PAGE_SIZES[this.state.banner.folha][1], color: item.color, position: 'absolute', top: parseFloat(item.y) * PAGE_SIZES[this.state.banner.folha][1], left: parseFloat(item.x) * PAGE_SIZES[this.state.banner.folha][0] });
                                    return (
                                        <View key={id} style={{
                                            transform: 'rotate(' + item.rotacao + 'deg)',
                                            // borderWidth:1,borderColor:'black',
                                            justifyContent:'center',alignItems:'center',
                                            height:parseFloat(item.height) * PAGE_SIZES[this.state.banner.folha][1],
                                            width: Math.ceil(parseFloat(item.width) * PAGE_SIZES[this.state.banner.folha][0]), color: item.color, position: 'absolute', top: parseFloat(item.y) * PAGE_SIZES[this.state.banner.folha][1], left: parseFloat(item.x) * PAGE_SIZES[this.state.banner.folha][0]
                                        }}>
                                            <Text style={{ width: '100%', fontFamily: item.fontFamily, fontWeight: item.fontWeight, fontSize: Math.round(parseInt(parseFloat(item.height) * PAGE_SIZES[this.state.banner.folha][1] / 1.56)),justifyContent:'center',alignItems:'center', textAlign: item.textAlign, }}>{item.value}</Text>
                                        </View>

                                    );
                                }
                                )}
                            </View>
                        </Page>

                    </Document>
                </PDFViewer>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>


        );
    }


}


const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Pdf);
