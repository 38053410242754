
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser,mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';

class Folhas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            folhas: [],
            page: 1,
            total_pages: 1,
            msgErro:'',
            show_warning:false,
            show_erro:false,
            folha_to_delete:''
        };
    }






    componentDidMount() {
        this.props.mudarTitle('Usuários');

        this.get_folhas(this.state.page);
    }

    get_folhas(page) {
        console.log('Page: ' + page);
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_folhas?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ folhas: resp.folhas, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    delete_folha(id) {

        fetch(`${URL}api/delete_folha/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro,show_warning:false,show_erro:true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({show_warning:false});
                    this.get_folhas(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }







    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                        <div className="float-right">
                            <Link to="/"><span
                                className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                    style={{fontSize:'15px', color:'white', fontStyle: 'normal',paddingRight:'1rem'}}> Voltar</i></span></Link>
                        </div>
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de Folhas</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todas as folhas do seu sistema</p>
                            <br />
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 col-md-6">

                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-end">
                                        <Link to="/folhas/criar"><span
                                            className="btn btn-primary btn-lg waves-effect waves-light" style={{ height: '45px' }}>Adicionar folha</span></Link>
                                    </div>
                                </div>
                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped">
                                            <thead>
                                                <tr>

                                                    <th>Nome</th>
                                                    <th data-priority="3">Ações</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.folhas.map((item, id) => (

                                                    <tr key={id}>

                                                        <th>{item.name}</th>

                                                        <td>
                                                       
                                                            <Link to={'/folhas/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle m-r-5"
                                                                data-toggle="tooltip"
                                                            ><i className="ti-pencil-alt"></i></span></Link>
                                                            <button onClick={()=>{
                                                                this.setState({show_warning:true,folha_to_delete:item.id});
                                                            }}
                                                                className="destroy_it btn btn-danger btn-outline btn-circle m-r-5"><i
                                                                    className="ti-trash"></i></button>
                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {this.state.folhas.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Nenhuma folha cadastrada</p>

                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <ReactPaginate
                                            previousLabel={'Anterior'}
                                            nextLabel={'Próxima'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick.bind(this)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            initialPage={this.state.page - 1}
                                        />
                                    </div>
                                </div>

                               

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => {
                                    this.delete_folha(this.state.folha_to_delete);
                                }}
                                onCancel={()=>{
                                    this.setState({show_warning:false});
                                }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                ao deletar esta folha ela não estará mais disponível no sistema

                                </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({show_erro:false});
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"
                                
                            >
                                {this.state.msgErro}

                                </SweetAlert>

                        </div>

                    </div>
                </div>
            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_folhas(page.selected + 1);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarTitle })(Folhas);


