import React from 'react';

const INITIAL_STATE = {
    loja: {},
    modelos: [],
    folhas: [],
    produtos: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'loja_modelo':
            return {
                ...state,
                loja: action.payload.loja,
                modelos: action.payload.modelos,
                folhas: action.payload.folhas,
                produtos: action.payload.produtos,

            };
        default:
            return { ...state };
    }
};
