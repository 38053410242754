
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tab, Tabs } from 'react-bootstrap';

// users
import moment from 'moment';
import { URL } from '../../variables';
import Banners from './Banners/Banners';
import Informacoes from './Informacoes';
import Modelos from './Modelos';
import ProdutosCliente from './Produtos/ProdutosCliente';

import ListasCliente from './listas_cliente/ListasCliente';
import ListaBannerCsv from './Banners_csv/ListaBannerCsv';
import ListasCsv from './Banners_csv/ListasCsv';

class Dados extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            loja: {},
            msgErro: '',
            tab: 0
        };
    }






    componentDidMount() {
        this.setState({tab:this.props.match.params.indice});
        // this.get_loja(this.props.match.params.id);
        this.props.mudarTitle(this.props.loja.name);

    }

    get_loja(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_loja/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.props.mudarTitle(resp.loja.name);

                    this.setState({ loja: resp.loja, loading_screen: false });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }






    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="float-right">
                                <Link to={"/"}><span
                                    className="btn btn-secondary btn-outline btn-circle m-r-5"><i className="mdi mdi-reply"
                                        style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}> Voltar</i></span></Link>
                            </div>

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 ">
                                        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.loja.name}</h4>
                                        <p style={{ fontSize: '1rem', color: 'black' }}>Adicione/Edite/Remova banners ou produtos</p>

                                    </div>


                                </div>

                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={this.state.tab}
                                    onSelect={(k) => this.setState({ tab: k })}
                                >

                                    <Tab tabClassName={'tab'}  eventKey={0} title={'Informações'}>
                                        <Informacoes loja_id={this.props.loja.id} />
                                    </Tab>
                                    <Tab tabClassName={'tab'}  eventKey={1} title={'Produtos'}>
                                        <ProdutosCliente loja_id={this.state.loja.id} />
                                    </Tab>
                                     <Tab tabClassName={'tab'}  eventKey={2} title={'Modelos'}>
                                        {/* <Prova indice={id} /> */}
                                        <Modelos loja_id={this.props.loja.id} />

                                    </Tab>
                                    <Tab tabClassName={'tab'}  eventKey={3} title={'Banners'}>
                                        {/* <Prova indice={id} /> */}
                                        <Banners loja_id={this.props.loja.id} />

                                    </Tab>
                                    <Tab tabClassName={'tab'}  eventKey={4} title={'Listas de Impressão'}>
                                        {/* <Prova indice={id} /> */}
                                        <ListasCliente loja_id={this.props.loja.id} />

                                    </Tab>
                                    <Tab tabClassName={'tab'}  eventKey={5} title={'Banners CSV'}>
                                        {/* <Prova indice={id} /> */}
                                        <ListasCsv loja_id={this.props.loja.id} />

                                    </Tab>
                                </Tabs>
                                {/* <div className="row mb-2 mt-4 mb-4">

                                    <div className="col-sm-6 col-12" style={{padding:0}}>
                                        <div onClick={()=>this.setState({tab:'andamento'})} style={{ height: 55, display: 'flex',cursor:'pointer', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid grey',borderBottom:this.state.tab=='andamento'?0:'1px solid lightgrey', borderTopLeftRadius: 5,borderTopRightRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff',backgroundColor: this.state.tab=='andamento'?'white':'lightgrey' }}>
                                            <i className="mdi mdi-book-open-variant" style={{ fontSize: '1.6rem', marginRight: '1rem' }}></i>
                                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.1rem', color: 'black' }}>Em Andamento</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-12" style={{padding:0}}>
                                        <div onClick={()=>this.setState({tab:'finalizadas'})} style={{ height: 55, display: 'flex',cursor:'pointer', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid grey',borderBottom:this.state.tab=='finalizadas'?0:'1px solid lightgrey', borderTopLeftRadius: 5,borderTopRightRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff',backgroundColor: this.state.tab=='finalizadas'?'white':'lightgrey' }}>
                                            <i className="mdi mdi-bookmark-check" style={{ fontSize: '1.6rem', marginRight: '1rem' }}></i>
                                            <p style={{ margin: 0, textAlign: 'center', fontSize: '1.1rem', color: 'black' }}>Finalizadas</p>
                                        </div>
                                    </div>
                                </div> */}

                                {/* {this.state.tab=='andamento' && <OfertasAndamento curso_id={this.props.match.params.id}/>}
                                {this.state.tab=='finalizadas' && <OfertasFinalizadas curso_id={this.props.match.params.id}/>} */}

                                {/* {this.state.tab=='andamento' && <OfertasFinalizadas curso_id={this.props.match.params.id}/>} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        loja: state.ClienteReducer.loja,

    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(Dados);


