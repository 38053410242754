export const maskCpf = (text) => {
    let text2 = text.replace('.', '').replace('-', '').replace('.', '');

    let final_num = '';

    for (let i = 0; i < text2.length && i < 11; i++) {
        // if (Number.isInteger(text[i])) {
        if (i == 3) {
            final_num += '.' + text2[i];
        }
        else if (i == 6) {
            final_num += '.' + text2[i];
        }
        else if (i == 9) {
            final_num += '-' + text2[i];
        }
        else {
            final_num += text2[i] + '';
        }
        // }
    }
    return final_num;
    // this.setState({ cpf: final_num });
}

export const maskNascimento = (text) => {
    let text2 = text.replace('/', '').replace('/', '');

    let final_num = '';

    for (let i = 0; i < text2.length && i < 8; i++) {
        // if (Number.isInteger(text[i])) {
        if (i == 2) {
            final_num += '/' + text2[i];
        }
        else if (i == 4) {
            final_num += '/' + text2[i];
        }
        else {
            final_num += text2[i] + '';
        }
        // }
    }
    return final_num;
}

export const maskTelefone = (text) => {
    let text2 = text.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
    console.log(text2.length);
    let final_num = '';

    for (let i = 0; i < text2.length && i < 11; i++) {
        // if (Number.isInteger(text[i])) {

        if (i == 0) {
            final_num += '(' + text2[i];
        }
        else if (i == 2) {
            final_num += ') ' + text2[i];
        }
        else if (i == 6 && text2.length <= 10) {

            final_num += '-' + text2[i];
        }
        else if (i == 7 && text2.length >= 11) {
            final_num += '-' + text2[i];
        }
        else {
            final_num += text2[i] + '';
        }
        // }
    }
    return final_num;
}

export const maskRg = (text) => {
    let text2 = text.replace('.', '').replace('-', '').replace('.', '');

    let final_num = '';

    for (let i = 0; i < text2.length && i < 9; i++) {
        // if (Number.isInteger(text[i])) {
        if (i == 2) {
            final_num += '.' + text2[i];
        }
        else if (i == 5) {
            final_num += '.' + text2[i];
        }
        else if (i == 8) {
            final_num += '-' + text2[i];
        }
        else {
            final_num += text2[i] + '';
        }
        // }
    }
    return final_num;
    // this.setState({ cpf: final_num });
}

export const maskCep = (text) => {
    let text2 = text.replace('-', '');


    let final_num = '';

    for (let i = 0; i < text2.length && i < 8; i++) {
        // if (Number.isInteger(text[i])) {
        if (i == 5) {
            final_num += '-' + text2[i];
        }
        else {
            final_num += text2[i] + '';
        }
        // }
    }
    return final_num;
    // this.setState({ cep: final_num });

}

export const maskPrice = (text) => {


    let text2 = text.replace('.', '');
    text2 = text2.replace('R', '');
    text2 = text2.replace('$', '');

    text2 = text2.replace(' ', '');
    let final_num = '';
    if (text2.length != 0)
        final_num = 'R$ ';
    for (let i = 0; i < text2.length; i++) {
        if (!isNaN(text2[i])) {
            if (i == text2.length - 3) {
                final_num += text2[i] + ',';
            }
            else {
                final_num += text2[i] + '';
            }
        }
    }
    return final_num;
}
