


import { combineReducers } from 'redux';
import AppReducer from './AppReducer';
import ClienteReducer from './ClienteReducer';

export default combineReducers({
   AppReducer,
   ClienteReducer
});