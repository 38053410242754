
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser ,mudarTitle} from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../../assets/images/users/user-4.jpg';
import moment from 'moment';
import { URL } from '../../variables';

class MenuItens extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true
        };
    }





    componentDidMount() {
        this.props.mudarTitle('Escolha uma opção');
    }




    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/usuarios"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-users" style={{ fontSize: '1.3rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Usuários</p>
                    </div></Link>
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/modelos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-list-ul" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Modelos</p>
                    </div></Link>
                </div> */}
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/lojas"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-store-alt" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Lojas</p>
                    </div></Link>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/folhas"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="far fa-file" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Folhas</p>
                    </div></Link>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-2">
                    <Link to="/tutoriais"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-chalkboard-teacher" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Tutoriais</p>
                    </div></Link>
                </div>
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/produtos"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-wine-bottle" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Produtos</p>
                    </div></Link>
                </div> */}
                {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link to="/banners"> <div style={{ height: 70, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'white', border: '1px solid lightgrey', borderRadius: 5, boxShadow: '-1px 1px #77aaff, -2px 2px #77aaff -3px 3px #77aaff, -4px 4px #77aaff,-5px 5px #77aaff' }}>
                        <i className="fas fa-images" style={{ fontSize: '1.8rem', marginRight: '1rem',color:'#C00D0E' }}></i>
                        <p style={{ margin: 0, textAlign: 'center', fontSize: '1.3rem', color: 'black' }}>Banners</p>
                    </div></Link>
                </div> */}
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarTitle })(MenuItens);


