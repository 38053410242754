
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser,mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';

class EditarLoja extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/lojas',
            loading_screen: true,
            name: '',
            
            loading_save: false,
            cliente_name:'',
            msgErro:'',
            loading_search_cliente:false,
            clientes:[],
            clientes_selecionados:{},
            show_autocomplete_cliente:false
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/editar_loja`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                loja_id:this.props.match.params.id,
                clientes:Object.keys(this.state.clientes_selecionados)
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,redirect:true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Lojas');

        this.get_loja(this.props.match.params.id);
    }

    get_loja(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_loja/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let clientes_selecionados={};
                    console.log('oi1');

                    for(let i=0;i<resp.clientes.length;i++){
                        clientes_selecionados[resp.clientes[i].id]=resp.clientes[i];
                    }
                    this.setState({ loading_screen: false, 
                        name:resp.loja.name,
                        clientes_selecionados
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    select_cliente(item) {
        let users = { ...this.state.clientes_selecionados };
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ cliente_name: '', show_autocomplete_cliente: false, clientes_selecionados: users })
    }

    get_cliente_search(search) {

        if (this.state.loading_search_cliente == false) {
            this.setState({ loading_search_cliente: true });
        }
        fetch(`${URL}api/clientes_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_cliente: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_cliente: false, clientes: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_cliente: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_cliente: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Loja</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma loja do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro!='' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{marginBottom:0,whiteSpace:'pre'}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.name} placeholder="Nome do usuário" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Clientes da Loja</label>
                                            <div className="col-sm-12">
                                                <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_cliente: true })} className="form-control" value={this.state.cliente_name} placeholder="Nome do cliente" type="text" onChange={(e) => { this.get_cliente_search(e.target.value); this.setState({ cliente_name: e.target.value }) }} />
                                                {this.state.show_autocomplete_cliente == true && this.state.cliente_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                                    {this.state.loading_search_cliente == false && this.state.clientes.map((item, id) => (
                                                        <div key={id} onClick={() => this.select_cliente(item)} className="opcao_autocomplete">
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                    {this.state.loading_search_cliente == false && this.state.clientes.length == 0 &&
                                                        <div className="opcao_autocomplete">
                                                            Nenhum cliente encontrado com este termo
                                                        </div>
                                                    }
                                                    {this.state.loading_search_cliente == true &&
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="spinner-border text-primary" role="status"  >
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                </div>}
                                            </div>


                                        </div>
                                        {Object.values(this.state.clientes_selecionados).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Clientes Selecionados:</label>

                                            {Object.values(this.state.clientes_selecionados).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    padding: 10, display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.name}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.clientes_selecionados };
                                                        delete (users[item.id]);
                                                        this.setState({ clientes_selecionados: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                       
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/lojas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar loja</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser,mudarTitle })(EditarLoja);


