import React from 'react';
import Header from './header';
import { connect } from 'react-redux';
import { logout, mudarUser, mudarTitle } from '../actions/AppActions';
import { mudarModeloLoja } from '../actions/ClienteActions';

import { URL } from '../variables';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ProcessoUser from './ProcessoUser';
import MenuItens from './Admin_Colab/MenuItens';
import Usuarios from './Admin_Colab/users/Usuarios';
import CriarUsuario from './Admin_Colab/users/CriarUsuario';
import EditarUsuario from './Admin_Colab/users/EditarUsuario';
import PageTitle from './PageTitle';

import Modelos from './Admin_Colab/lojas/modelos/Modelos';
import CriarModelo from './Admin_Colab/lojas/modelos/CriarModelo';
import EditarModelo from './Admin_Colab/lojas/modelos/EditarModelo';
import Lojas from './Admin_Colab/lojas/Lojas';
import CriarLoja from './Admin_Colab/lojas/CriarLoja';
import EditarLoja from './Admin_Colab/lojas/EditarLoja';
import VerLoja from './Admin_Colab/lojas/VerLoja';
import CriarProduto from './Admin_Colab/lojas/Produtos/CriarProduto';
import EditarProduto from './Admin_Colab/lojas/Produtos/EditarProduto';
import CriarBanner from './Admin_Colab/lojas/Banners/CriarBanner';


import MenuItens_Cliente from './Cliente/MenuItens';
import Dados from './Cliente/Dados';
import Folhas from './Admin_Colab/Folhas/Folhas';
import CriarFolha from './Admin_Colab/Folhas/CriarFolha';
import EditarFolha from './Admin_Colab/Folhas/EditarFolha';
import ModelBanner from './Cliente/Banners/ModelBanner';
import TutoriaisCliente from './Cliente/Tutoriais/TutoriaisCliente';

import ModelBannerAdm from './Admin_Colab/lojas/Banners/ModelBannerAdm';
import Tutoriais from './Admin_Colab/Tutoriais/Tutoriais';
import CriarTutorial from './Admin_Colab/Tutoriais/CriarTutorial';
import EditarTutorial from './Admin_Colab/Tutoriais/EditarTutorial';
import CriarLista from './Admin_Colab/lojas/listas/CriarLista';
import EditarLista from './Admin_Colab/lojas/listas/EditarLista';

import CriarListaCliente from './Cliente/listas_cliente/CriarListaCliente';
import EditarListaCliente from './Cliente/listas_cliente/EditarListaCliente';
import CriarProdutoCliente from './Cliente/Produtos/CriarProdutoCliente';
import EditarProdutoCliente from './Cliente/Produtos/EditarProdutoCliente';
import ListaBannerCsv from './Cliente/Banners_csv/ListaBannerCsv';
import EditarListaBannerCsv from './Cliente/Banners_csv/EditarListaBannerCsv';

import ListaBannerCsv2 from './Admin_Colab/lojas/Banners_csv/ListaBannerCsv';
import EditarListaBannerCsv2 from './Admin_Colab/lojas/Banners_csv/EditarListaBannerCsv';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
    }
  />
);
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // redirect:'/not_processo',
      loading_home: true

    }
    this.videoRef = React.createRef();

    // this.videoRef=null;
  }
  logout() {
    localStorage.removeItem('token');
    // this.setState({redirect:true,path:'/'});
    this.props.logout();
  }

  componentDidMount() {
    // this.setState({loading_home:true});

    this.get_user();

    // console.log('montou');
    // var socket = io.connect("http://localhost:3001", { "transports": ['websocket'] });
    // socket.on('welcome', function (data) {
    //   console.log(data);
    //   // me = data.id;
    //   // socket.emit("join", { name: 'Aluno', sala_id: -1, aluno_id: aluno_id, faculdade_id: faculdade_id });
    // });
    // socket.on("comunicado_geral" + faculdade_id, function (data) {
    //   let id_msg_comunicado = uuidv4();
    //   $('#msg_notifi_comunicado').append(template_msg_notifi_comunicado.replace(/%MSG%/g, data.msg).replace(/%NAME%/g, data.nome_chat).replace(/%ID%/g, 'comunicado' + id_msg_comunicado));
    //   setTimeout(() => {
    //     $('#comunicado' + id_msg_comunicado).remove();
    //   }, 15000);
    // });


  }

  get_user() {
    fetch(`${URL}api/get_user/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',

        'Authorization': `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          this.setState({ loading_home: false });
        }
        else {
          this.props.mudarUser(resp.user);
          if (resp.user.role == 'Cliente') {
            if (resp.loja != null) {
              let produtos = {};
              for (let i = 0; i < resp.produtos.length; i++) {
                produtos[resp.produtos[i].id] = resp.produtos[i];
              }
              this.props.mudarModeloLoja({ loja: resp.loja, modelos: resp.modelos, folhas: resp.folhas,produtos:produtos });
            }
            else {
              this.props.mudarTitle('Você não está vinculado a nenhuma loja');

            }

          }
          this.setState({ loading_home: false });

          // this.props.mudarFaculdade(resp.faculdade);
          // // if (resp.processo != null) {
          // this.props.mudarProcesso(resp.processo);
          // // }
          // // if (resp.sala != null) {
          // this.props.mudarSala(resp.sala);
          // // }
          // this.props.mudarLoadingHome(false);

        }




      } catch (err) {
        console.log(err);
        this.setState({ loading_home: false });

      }

    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading_home: false });
      });
  }



  render() {
    console.log(`${this.props.match.url}listas/criar`);
    return (
      <div>
        {/* {this.state.redirect == true && <Redirect to={this.state.path} />} */}

        <div id="wrapper">
          <Header />
          {this.state.loading_home == false && <div className="">

            <div className="content-page" style={{ marginLeft: 0 }}>
              <div className="content">


                <div className="container-fluid">
                  <PageTitle />
                  {/* {this.props.processo != null &&
                   } */}
                  {(this.props.user.role == 'Cliente' && this.props.loja.id != null) && <Switch>
                    <Route exact path={`${this.props.match.url}`} render={(props2) => <MenuItens_Cliente {...props2} getUser={()=>this.get_user()} />} />
                    <Route exact path={`${this.props.match.url}dados/:indice`} render={(props2) => <Dados {...props2} />} />
                    <Route exact path={`${this.props.match.url}model_banner/:id`} render={(props2) => <ModelBanner {...props2} />} />
                    <Route exact path={`${this.props.match.url}tutoriais`} render={(props2) => <TutoriaisCliente {...props2} />} />
                    <Route exact path={`${this.props.match.url}listas_cliente/criar`} render={(props2) => <CriarListaCliente {...props2} />} />
                    <Route exact path={`${this.props.match.url}listas_cliente/editar/:id`} render={(props2) => <EditarListaCliente {...props2} />} />

                    <Route exact path={`${this.props.match.url}produtos/criar/:id`} render={(props2) => <CriarProdutoCliente {...props2} />} />
                    <Route exact path={`${this.props.match.url}produtos/editar/:id`} render={(props2) => <EditarProdutoCliente {...props2} />} />

                    <Route exact path={`${this.props.match.url}listas_csv/criar`} render={(props2) => <ListaBannerCsv {...props2} />} />

                    <Route exact path={`${this.props.match.url}listas_csv/editar/:id`} render={(props2) => <EditarListaBannerCsv {...props2} />} />


                  </Switch>}
                  {(this.props.user.role == 'Admin' || this.props.user.role == 'Colaborador') && <Switch>
                    <Route exact path={`${this.props.match.url}`} render={(props2) => <MenuItens {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios`} render={(props2) => <Usuarios {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios/criar`} render={(props2) => <CriarUsuario {...props2} />} />
                    <Route exact path={`${this.props.match.url}usuarios/editar/:id`} render={(props2) => <EditarUsuario {...props2} />} />
                    {/* <Route exact path={`${this.props.match.url}modelos`} render={(props2) => <Modelos {...props2} />} /> */}
                    <Route exact path={`${this.props.match.url}modelos/criar/:id`} render={(props2) => <CriarModelo {...props2} />} />
                    <Route exact path={`${this.props.match.url}modelos/editar/:id`} render={(props2) => <EditarModelo {...props2} />} />

                    <Route exact path={`${this.props.match.url}lojas`} render={(props2) => <Lojas {...props2} />} />
                    <Route exact path={`${this.props.match.url}lojas/criar`} render={(props2) => <CriarLoja {...props2} />} />
                    <Route exact path={`${this.props.match.url}lojas/editar/:id`} render={(props2) => <EditarLoja {...props2} />} />
                    <Route exact path={`${this.props.match.url}ver_loja/:id/:indice`} render={(props2) => <VerLoja {...props2} />} />
                    <Route exact path={`${this.props.match.url}loja/produtos/criar/:id`} render={(props2) => <CriarProduto {...props2} />} />
                    <Route exact path={`${this.props.match.url}loja/produtos/editar/:id`} render={(props2) => <EditarProduto {...props2} />} />

                    <Route exact path={`${this.props.match.url}loja/banners/criar/:id`} render={(props2) => <CriarBanner {...props2} />} />
                    <Route exact path={`${this.props.match.url}loja/model_banner/:id`} render={(props2) => <ModelBannerAdm {...props2} />} />

                    <Route exact path={`${this.props.match.url}listas/criar/:id`} render={(props2) => <CriarLista {...props2} />} />
                    <Route exact path={`${this.props.match.url}listas/editar/:id`} render={(props2) => <EditarLista {...props2} />} />


                    <Route exact path={`${this.props.match.url}listas_csv/criar/:loja_id`} render={(props2) => <ListaBannerCsv2 {...props2} />} />

                    <Route exact path={`${this.props.match.url}listas_csv/editar/:id`} render={(props2) => <EditarListaBannerCsv2 {...props2} />} />


                    <Route exact path={`${this.props.match.url}folhas`} render={(props2) => <Folhas {...props2} />} />
                    <Route exact path={`${this.props.match.url}folhas/criar`} render={(props2) => <CriarFolha {...props2} />} />
                    <Route exact path={`${this.props.match.url}folhas/editar/:id`} render={(props2) => <EditarFolha {...props2} />} />

                    <Route exact path={`${this.props.match.url}tutoriais`} render={(props2) => <Tutoriais {...props2} />} />
                    <Route exact path={`${this.props.match.url}tutoriais/criar`} render={(props2) => <CriarTutorial {...props2} />} />
                    <Route exact path={`${this.props.match.url}tutoriais/editar/:id`} render={(props2) => <EditarTutorial {...props2} />} />
                    {/* <Route exact path={`${this.props.match.url}cursos`} render={(props2) => <Cursos {...props2} />} />
                     <Route exact path={`${this.props.match.url}cursos/criar`} render={(props2) => <CriarCurso {...props2} />} />
                     <Route exact path={`${this.props.match.url}cursos/editar/:id`} render={(props2) => <EditarCurso {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_curso/:id`} render={(props2) => <VerCurso {...props2} />} />

                     <Route exact path={`${this.props.match.url}turmas/:id`} render={(props2) => <Turmas {...props2} />} />
                     <Route exact path={`${this.props.match.url}turmas/criar/:id`} render={(props2) => <CriarTurma {...props2} />} />
                     <Route exact path={`${this.props.match.url}turmas/editar/:id`} render={(props2) => <EditarTurma {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_turma/:id`} render={(props2) => <VerTurma {...props2} />} />

                     <Route exact path={`${this.props.match.url}disciplinas`} render={(props2) => <Disciplinas {...props2} />} />
                     <Route exact path={`${this.props.match.url}disciplinas/criar/`} render={(props2) => <CriarDisciplina {...props2} />} />
                     <Route exact path={`${this.props.match.url}disciplinas/editar/:id`} render={(props2) => <EditarDisciplina {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_disciplina/:id`} render={(props2) => <VerTurma {...props2} />} />
                     

                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/:id`} render={(props2) => <Ofertas {...props2} />} />
                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/criar/:id`} render={(props2) => <CriarOferta {...props2} />} />
                     <Route exact path={`${this.props.match.url}ofertas_disciplinas/editar/:id`} render={(props2) => <EditarOferta {...props2} />} />
                     <Route exact path={`${this.props.match.url}ver_oferta/:id`} render={(props2) => <VerOferta {...props2} />} /> */}

                    {/* <Route exact path={`${this.props.match.url}`} render={(props2) => <Termos {...props2} />} /> */}
                    {/* <Route exact path={`${this.props.match.url}`} render={(props2) => { return(<ProcessoUser {...props2} />)}} /> */}


                    {/* {this.props.processo == null && <Route exact path={`${this.props.match.url}`} component={(props2) => <NotProcesso {...props2} />} />}  */}

                    {/* <OtherRoute path="*" path_name='/' /> */}

                  </Switch>}
                  {/* { this.props.children } */}
                  {/* {this.props.processo == null &&
                    <NotProcesso />} */}
                </div>
              </div>
            </div>
          </div>}
          {this.state.loading_home == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
            <div className="spinner-border text-primary" role="status"  >
              <span className="sr-only">Loading...</span>
            </div>
          </div>}

        </div>

      </div >

    );
  }


}

const mapsStateToProps = (state) => (
  {
    user: state.AppReducer.user,
    token: state.AppReducer.token,
    loja: state.ClienteReducer.loja,

  }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarModeloLoja, mudarTitle })(Home);
