
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Draggable from 'react-draggable'; // The default
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { saveAs } from 'file-saver';
import { SketchPicker, PhotoshopPicker } from 'react-color';
import ScaleText from "react-scale-text";
import DragResizeContainer from 'react-drag-resize';
import JoditEditor from "jodit-react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const canResizable = () => {
    return { top: true, right: true, bottom: true, left: true, topRight: true, bottomRight: true, bottomLeft: true, topLeft: true };
};
const layout = [{ key: 'test', x: 0, y: 0, width: 200, height: 100, zIndex: 1 }]
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
class ModelBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listas: [],
            listas_atuais: [],
            listas_selecionadas: {},
            lista_id: '',
            lista_modal: false,
            loading_lista: false,
            name_lista: '',
            msgErroLista: '',
            banner_id: '',

            redirect: false,
            path: '/ver_loja/',
            loading_screen: true,
            name: '',
            loading_save: false,
            produto_name: '',
            msgErro: '',
            loading_search_produto: false,
            produtos: [

            ],
            produtos_selecionados: {},
            show_autocomplete_produto: false,
            modelos: [],
            modelo_id: '',
            loja: {},
            width: 100,
            height: '600px',
            dado_select: -1,
            folhas: [
                {
                    indice: 0,
                    name: 'A0',
                    width: 2384,
                    height: 3369
                },
                {
                    indice: 1,
                    name: 'A1',
                    width: 1684,
                    height: 2383
                },
                {
                    indice: 2,
                    name: 'A2',
                    width: 1191,
                    height: 1683
                },
                {
                    indice: 3,
                    name: 'A3',
                    width: 842,
                    height: 1190
                },
                {
                    indice: 4,
                    name: 'A4',
                    width: 595,
                    height: 840
                }
            ],
            show_pickers: [
                false,
                false,
                false,
                false,
                false,
                false,

            ],
            folha_id: 0,
            dados_banner: [
                // { ref: React.createRef(),editable:false, show_toolbar: false, key: uuidv4(), id: 0, fontFamily: 'Arial Black', value: 'Nome', name: 'Texto', color: '#000000', fontSize: 30, x: 106, y: 127, width: '100%', height: 108, zIndex: 1, textAlign: 'center' },
                // { ref: React.createRef(),editable:false, show_toolbar: false, key: uuidv4(), id: 1, fontFamily: 'Arial Black', value: 'Marca/Tipo', name: 'Texto', color: '#000000', fontSize: 35, x: 65, y: 230, width: '100%', height: 76, zIndex: 1, textAlign: 'center' },
                // { ref: React.createRef(),editable:false, show_toolbar: false, key: uuidv4(), id: 1, fontFamily: 'Arial Black', value: 'Tamanho', name: 'Texto', color: '#000000', fontSize: 35, y: 326, x: 248, width: 173, height: 46, zIndex: 1, textAlign: 'center' },

                // { ref: React.createRef(),editable:false, show_toolbar: false, key: uuidv4(), value: '0,00', fontFamily: 'Arial Black', id: 2, name: 'Texto', color: '#000000', fontSize: 30, x: 57, y: 401, width: 370, height: 166, zIndex: 1, textAlign: 'center' },
                // { ref: React.createRef(),editable:false, show_toolbar: false, key: uuidv4(), value: 'R$', fontFamily: 'Arial Black', id: 2, name: 'Texto', color: '#000000', fontSize: 40, y: 482, x: 37, width: 100, height: 50, zIndex: 1, textAlign: 'center' },
            ],
            fonts: [
                'Georgia',
                "Times New Roman",
                'Arial',
                'Helvetica',
                "Arial Black",
                "Comic Sans MS",
                'Impact',
            ],
            meio_x: false,
            meio_y: false,
            produtos: {},
            produto: { id: '' }

        };
        this.width = 0;
        this.canvasRef = React.createRef();
        this.image_ref = React.createRef();
        this.bannerRef = React.createRef();
        this.containerRef = React.createRef();
        this.refRotate = React.createRef();

        this.deletedElements = [];


    }

    // ImagetoPrint(source) {
    //     // <style>@page{size: A4;margin: 0;}@media print {html, body {width: 210mm;height: 297mm;}}</style>
    //     console.log(this.bannerRef.current);
    //     return "<html><head><scri" + "pt>function step1(){\n" +
    //         "setTimeout('step2()', 10);}\n" +
    //         "function step2(){window.print();window.close()}\n" +
    //         "</scri" + "pt></head><body style=\"margin:0\" onload='step1()'>\n" +
    //         "<img style=\"width:100%\" src='" + source + "' /></body></html>"
    // }

    // print(link) {
    //     var Pagelink = "about:blank";
    //     var pwa = window.open(Pagelink, "_new");
    //     pwa.document.open();
    //     pwa.document.write(this.ImagetoPrint(link));
    //     pwa.document.close();
    // }

    salvar() {
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('folha', this.props.folhas[this.state.folha_id] != undefined ? this.props.folhas[this.state.folha_id] : '');
        form.append('modelo_id', this.state.modelo_id);
        form.append('banner_id', this.props.match.params.id);
        // form.append('produto_id', this.state.produto.id);

        for (let i = 0; i < this.state.dados_banner.length; i++) {
            let dados = { ...this.state.dados_banner[i] };
            dados.value = dados.ref.current.textContent;
            dados.height = dados.height / 599;
            dados.width = dados.width / this.state.width;
            dados.x = dados.x / this.state.width;
            dados.y = dados.y / 599;
            delete dados.ref;
            delete dados.refInput;
            form.append('dados_banner[]', JSON.stringify(dados));
        }
        fetch(`${URL}api/cliente/salvar_banner`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                // console.log('/link/' + resp.banner.arquivo.replace(/\//g, '-'));
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    // var urlCreator = window.URL || window.webkitURL;
                    // var imageUrl = urlCreator.createObjectURL(arquivo);
                    // this.setState({ loading_save: false });
                    let listas_selecionadas = {};
                    for (let i = 0; i < resp.listas_atuais.length; i++) {
                        listas_selecionadas[resp.listas_atuais[i].id] = resp.listas_atuais[i];
                    }
                    this.setState({ loading_save: false, path: '/cliente/link/' + resp.banner.id, listas: resp.listas, listas_selecionadas, lista_id: '', lista_modal: true, msgErroLista: '', name_lista: '', banner_id: resp.banner.id, listas_selecionadas: {} });
                    // setTimeout(() => {
                    //     var linkToClick = document.getElementById('gerar');
                    //     linkToClick.click();
                    // }, 500);

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_data(this.props.match.params.id);
    }

    get_data(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/cliente/get_banner_model/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let folha_id = 0;
                    for (let i = 0; i < this.props.folhas.length; i++) {
                        if (this.props.folhas[i] == resp.banner.folha) {
                            folha_id = i;
                            break;
                        }
                    }
                    let dados = resp.banner.dados;
                    console.log(dados);

                    let modelo = {};
                    let image = new Image();
                    for (let i = 0; i < this.props.modelos.length; i++) {
                        if (this.props.modelos[i].id == resp.banner.modelo_id) {
                            modelo = this.props.modelos[i];
                            break;
                        }
                    }
                    let produtos = {};
                    for (let i = 0; i < resp.produtos.length; i++) {
                        produtos[resp.produtos[i].id] = resp.produtos[i];
                    }
                    let produto = { id: '' };
                    if (resp.banner.produto_id != null && produtos[resp.banner.produto_id] != undefined) {
                        produto = produtos[resp.banner.produto_id];
                    }
                    this.setState({
                        loading_screen: false,
                        modelo_id: resp.banner.modelo_id,
                        produtos: produtos,
                        produto

                    });
                    image.src = URL + 'storage/' + modelo.arquivo;
                    image.onload = (function () {
                        // let context = this.canvasRef.current.getContext('2d');
                        // context.drawImage(image,0,0);
                        // let image_data = this.canvasRef.current.toDataURL();
                        let ratio_h = 600 / image.height;

                        for (let i = 0; i < dados.length; i++) {
                            dados[i].ref = React.createRef();
                            dados[i].show_toolbar = false;
                            dados[i].key = uuidv4();
                            dados[i].zIndex = 1;
                            dados[i].width = parseFloat(dados[i].width) * image.width * ratio_h;
                            dados[i].height = parseFloat(dados[i].height) * 599;
                            dados[i].x = parseFloat(dados[i].x) * image.width * ratio_h;
                            dados[i].y = parseFloat(dados[i].y) * 599;
                            dados[i].rotate = dados[i].rotacao;

                            dados[i].editable = false;
                        }
                        this.image_ref.current.src = URL + 'storage/' + modelo.arquivo;
                        this.setState({
                            width: image.width * ratio_h, folha_id: folha_id,
                            dados_banner: dados,
                            name: resp.banner.name
                        }, () => {
                            for (let i = 0; i < dados.length; i++) {
                                dados[i].refInput.style.transform = 'rotate(' + dados[i].rotacao + 'deg)'
                            }
                        });
                        // this.width = image.width * ratio_h;

                        // this.image_ref.current.src = URL + 'storage/' + modelo.arquivo;
                        // let ratio_h = 600 / this.state.folhas[this.state.folha_id].height;
                        // this.setState({ width: this.state.folhas[this.state.folha_id].width * ratio_h });
                        // console.log(this.image_ref.current)
                        // this.width = this.state.folhas[this.state.folha_id].width * ratio_h;
                    }).bind(this);

                    // if (resp.processo != null) {
                    this.props.mudarTitle('Banners');


                }




            } catch (err) {
                // this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                // this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }
    change_folha(event) {
        // let ratio_h = 600 / this.state.folhas[this.state.folha_id].height;
        // this.width = this.state.folhas[this.state.folha_id].width * ratio_h;
        // this.setState({ folha_id: event.target.value, width: this.state.folhas[this.state.folha_id].width * ratio_h });
        this.setState({ folha_id: event.target.value });

    }
    select_produto(item) {
        let produtos = {};
        if (produtos[item.id] == undefined) {
            produtos[item.id] = item;
        }
        let dados = {};
        dados['name'] = { id: 'name', fontFamily: 'Georgia, serif', value: item.name, top: 110, bottom: 0, right: 50, left: 50, name: 'Nome do produto', color: 'black', fontSize: 30 };
        dados['preco_antes'] = { id: 'preco_antes', fontFamily: 'Georgia, serif', value: 'De R$ ' + item.preco_antes.replace('.', ','), top: 280, bottom: 0, right: 0, left: 0, name: 'Preço normal', color: 'red', fontSize: 35 };
        dados['preco_promocao'] = { value: 'Por R$ ' + item.preco_promocao.replace('.', ','), fontFamily: 'Georgia, serif', id: 'preco_promocao', top: 450, bottom: 0, right: 0, left: 0, name: 'Preço na promoção', color: 'green', fontSize: 40 };

        this.setState({ produto_name: '', show_autocomplete_produto: false, produtos_selecionados: produtos, dados_banner: dados })
    }

    get_produto_search(search) {

        if (this.state.loading_search_produto == false) {
            this.setState({ loading_search_produto: true });
        }
        fetch(`${URL}api/produtos_search?search=${search}&id=${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_produto: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_produto: false, produtos: resp.produtos });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_produto: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_produto: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    change_modelo(event) {

        let modelo = {};
        let image = new Image();
        for (let i = 0; i < this.props.modelos.length; i++) {
            if (this.props.modelos[i].id == event.target.value) {
                modelo = this.props.modelos[i];
                break;
            }
        }
        let event_id = event.target.value;

        image.src = URL + 'storage/' + modelo.arquivo;
        image.onload = (function () {
            // let context = this.canvasRef.current.getContext('2d');
            // context.drawImage(image,0,0);
            // let image_data = this.canvasRef.current.toDataURL();
            if (this.state.modelo_id == event_id) {

                this.image_ref.current.src = URL + 'storage/' + modelo.arquivo;
                let ratio_h = 600 / image.height;
                this.setState({ width: image.width * ratio_h });
            }
            // this.width = image.width * ratio_h;

            // this.image_ref.current.src = URL + 'storage/' + modelo.arquivo;
            // let ratio_h = 600 / this.state.folhas[this.state.folha_id].height;
            // this.setState({ width: this.state.folhas[this.state.folha_id].width * ratio_h });
            // console.log(this.image_ref.current)
            // this.width = this.state.folhas[this.state.folha_id].width * ratio_h;
        }).bind(this);

        this.setState({ modelo_id: event.target.value });
    }

    gerar_imagem() {
        this.setState({ loading_save: true, msgErro: '', dado_select: -1 });

        this.salvar();
    }

    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Novo Banner</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Use o template de um banner para criar outros</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome</label>
                                            <div className="col-sm-12">
                                                <input value={this.state.name} className="form-control" placeholder="Nome do banner" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>
                                        <canvas hidden ref={this.canvasRef}></canvas>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Folha</label>
                                            <div className="col-sm-12">
                                                <select value={this.state.folha_id} className="custom-select" id="cameras" onChange={this.change_folha.bind(this)}>
                                                    <option value={''}>Selecione um tamanho de folha</option>

                                                    {this.props.folhas.map((item, id) => (
                                                        <option key={id} value={id}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {this.state.produto.id != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Produto</label>
                                            <div className="col-sm-12">
                                                <select value={this.state.produto.id} className="custom-select" id="cameras" disabled>

                                                    {Object.values(this.state.produtos).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Modelo</label>
                                            <div className="col-sm-12">
                                                <select value={this.state.modelo_id} className="custom-select" id="cameras" onChange={this.change_modelo.bind(this)}>
                                                    <option value={''}>Selecione um modelo</option>

                                                    {this.props.modelos.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="col-sm-12">
                                                {/* {this.state.dados_banner.map((item, id) => (
                                                    <div key={id}>
                                                        <hr />

                                                        <div key={id} className="form-group row">
                                                            <div className="col-sm-6 mb-2">
                                                                <label className="col-form-label">{item.name}</label>
                                                                <input value={item.value} className="form-control" placeholder="Texto" type="text" onChange={(e) => {
                                                                    let dados = [...this.state.dados_banner];
                                                                    console.log(dados);
                                                                    dados[id].value = e.target.value;
                                                                    this.setState({ dados_banner: dados });
                                                                }} />

                                                            </div>
                                                            <div className="col-sm-6 mb-2">
                                                                <label className="col-form-label">Família da Fonte</label>

                                                                <select value={item.fontFamily} className="custom-select" id="cameras" onChange={(e) => {
                                                                    let dados = [...this.state.dados_banner];
                                                                    dados[id].fontFamily = e.target.value;
                                                                    this.setState({ dados_banner: dados });
                                                                }}>
                                                                    {this.state.fonts.map((item, id) => (
                                                                        <option key={id} value={item}>{item}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="col-sm-6 mb-2">
                                                                <label className="col-form-label">Cor do texto</label>
                                                                <input onFocus={() => {
                                                                    let show_pickers = [...this.state.show_pickers]
                                                                    show_pickers[id] = true;
                                                                    this.setState({ show_pickers });
                                                                }} value={item.color} className="form-control mb-1" placeholder="Cor do texto" type="text" onChange={(e) => {
                                                                    let dados = [...this.state.dados_banner];
                                                                    dados[id].color = e.target.value;
                                                                    this.setState({ dados_banner: dados });
                                                                }} />
                                                                {this.state.show_pickers[id] == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                                                                    let show_pickers = [...this.state.show_pickers]
                                                                    show_pickers[id] = false;
                                                                    this.setState({ show_pickers });
                                                                }} onCancel={() => {
                                                                    let show_pickers = [...this.state.show_pickers]
                                                                    show_pickers[id] = false;
                                                                    this.setState({ show_pickers });
                                                                }} color={item.color} onChange={(color) => {
                                                                    let dados = [...this.state.dados_banner];
                                                                    dados[id].color = color.hex;
                                                                    this.setState({ dados_banner: dados });

                                                                }} /></div>}
                                                            </div>
                                                            <div className="col-sm-6 mb-2">
                                                                <label className="col-form-label">Tamanho da fonte</label>
                                                                <input value={item.fontSize} className="form-control" placeholder="Tamanho da fonte" type="number" onChange={(e) => {
                                                                    let dados = [...this.state.dados_banner];
                                                                    dados[id].fontSize = parseInt(e.target.value);
                                                                    this.setState({ dados_banner: dados });
                                                                }} />

                                                            </div>

                                                        </div>

                                                    </div>

                                                ))} */}
                                                <div className="row d-flex justify-content-end mt-5">

                                                    <button type="button" onClick={() => {
                                                        let dados = [...this.state.dados_banner];
                                                        if (dados[this.state.dado_select] != undefined) {
                                                            let delete_ = { ...dados[this.state.dado_select] };
                                                            dados = dados.filter((item, id) => {
                                                                if (id != this.state.dado_select) {
                                                                    return true;
                                                                }
                                                            });
                                                            this.deletedElements.unshift({ element: { ...delete_ }, status: 'delete' });
                                                            let show_pickers = [...this.state.show_pickers];
                                                            show_pickers.pop();
                                                            this.setState({ dados_banner: dados, show_pickers, dado_select: -1 });
                                                            this.containerRef.current.focus();

                                                        }
                                                    }} className="btn btn-danger btn-lg waves-effect waves-light mr-2"
                                                        style={{ backgorundColor: 'indianred' }}>Remover Texto</button>
                                                    <button type="button" onClick={() => {
                                                        let dados = [...this.state.dados_banner];
                                                        let show_pickers = [...this.state.show_pickers];
                                                        show_pickers.push(false);

                                                        let element = { ref: React.createRef(), rotate: '0', editable: false, show_toolbar: false, key: uuidv4(), x: 0, y: 0, width: 200, height: 100, zIndex: 1, value: 'Texto', fontFamily: 'Arial Black', id: 2, name: 'Texto', color: '#000000', fontSize: 30, textAlign: 'center' };
                                                        dados.push(element);
                                                        this.deletedElements.unshift({ element: { ...element }, status: 'add' });

                                                        this.setState({ dados_banner: dados, show_pickers });
                                                        this.containerRef.current.focus();

                                                    }} className="btn btn-success btn-lg waves-effect waves-light"
                                                    >Adicionar +1 Texto</button>

                                                </div>
                                            </div>
                                            <div hidden={this.state.modelo_id == ''} className="col-sm-12" style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                                                <span style={{ color: 'black', fontFamily: '"Comic Sans MS", cursive, sans-serif', fontSize: '17px' }}>Clique e arraste os textos para mudá-los de lugar</span>
                                            </div>
                                            {<div className="col-12"><div className="form-group row">
                                                <div className="col-sm-4 mb-2">
                                                    <label className="col-form-label">Família da Fonte</label>

                                                    <select value={this.state.dados_banner[this.state.dado_select] == undefined ? '' : this.state.dados_banner[this.state.dado_select].fontFamily} className="custom-select" id="cameras" onChange={(e) => {
                                                        if (this.state.dados_banner[this.state.dado_select] != undefined) {
                                                            let dados = [...this.state.dados_banner];
                                                            dados[this.state.dado_select].fontFamily = e.target.value;
                                                            this.setState({ dados_banner: dados });
                                                        }
                                                    }}>
                                                        {this.state.fonts.map((item, id) => (
                                                            <option key={id} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {/* <div className="col-sm-4 mb-2">
                                                    <label className="col-form-label">Cor do texto</label>
                                                    <input onFocus={() => {
                                                        let show_pickers = [...this.state.show_pickers]
                                                        show_pickers[this.state.dado_select] = true;
                                                        this.setState({ show_pickers });
                                                    }} value={this.state.dados_banner[this.state.dado_select].color} className="form-control mb-1" placeholder="Cor do texto" type="text" onChange={(e) => {
                                                        let dados = [...this.state.dados_banner];
                                                        dados[this.state.dado_select].color = e.target.value;
                                                        this.setState({ dados_banner: dados });
                                                    }} />
                                                    {this.state.show_pickers[this.state.dado_select] == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                                                        let show_pickers = [...this.state.show_pickers]
                                                        show_pickers[this.state.dado_select] = false;
                                                        this.setState({ show_pickers });
                                                    }} onCancel={() => {
                                                        let show_pickers = [...this.state.show_pickers]
                                                        show_pickers[this.state.dado_select] = false;
                                                        this.setState({ show_pickers });
                                                    }} color={this.state.dados_banner[this.state.dado_select].color} onChange={(color) => {
                                                        let dados = [...this.state.dados_banner];
                                                        dados[this.state.dado_select].color = color.hex;
                                                        this.setState({ dados_banner: dados });

                                                    }} /></div>}
                                                </div> */}
                                                {/* <div className="col-sm-4 mb-2">
                                                    <label className="col-form-label">Tamanho da fonte</label>
                                                    <input value={this.state.dados_banner[this.state.dado_select].fontSize} className="form-control" placeholder="Tamanho da fonte" type="number" onChange={(e) => {
                                                        let dados = [...this.state.dados_banner];
                                                        dados[this.state.dado_select].fontSize = parseInt(e.target.value);
                                                        this.setState({ dados_banner: dados });
                                                    }} />

                                                </div> */}

                                            </div></div>}
                                            <div hidden={this.state.modelo_id == ''} className="col-sm-12" style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginTop: 15 }}
                                                onClick={() => {
                                                    if (this.state.dado_select != -1 && this.state.dados_banner[this.state.dado_select].editable == true) {
                                                        return;
                                                    }
                                                    if (this.containerRef.current != null) {
                                                        this.containerRef.current.focus();
                                                    }
                                                }}
                                                {...this.state.dado_select == -1 || this.state.dados_banner[this.state.dado_select].editable == false ? {
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                        let id = this.state.dado_select;
                                                        if (id != -1) {

                                                            // console.log(e.metaKey);
                                                            // console.log(e.key);
                                                            if ((e.metaKey || e.ctrlKey) && e.key == 'c') {
                                                                this.copy_element = { ...this.state.dados_banner[id] };
                                                            }

                                                            else if ((e.metaKey || e.ctrlKey) && e.key == 'v') {
                                                                if (this.copy_element != null) {
                                                                    let dados = [...this.state.dados_banner];
                                                                    if (this.copy_element.y + 10 < 599) {
                                                                        this.copy_element.y += 10;
                                                                    }
                                                                    else if (this.copy_element.y - 10 > 0) {
                                                                        this.copy_element.y -= 10;
                                                                    }
                                                                    this.copy_element.key = uuidv4();
                                                                    dados.push({ ...this.copy_element });
                                                                    this.setState({ dados_banner: dados });
                                                                    this.deletedElements.unshift({ element: { ...this.copy_element }, status: 'add' });

                                                                }

                                                            }
                                                            else if (e.key == 'Delete') {
                                                                let delete_ = null;

                                                                let dados = this.state.dados_banner.filter((item, i) => {
                                                                    if (i != id) {
                                                                        return true;
                                                                    }
                                                                    else {
                                                                        delete_ = item;
                                                                    }
                                                                });
                                                                if (delete_ != null) {
                                                                    this.deletedElements.unshift({ element: { ...delete_ }, status: 'delete' });
                                                                    console.log(this.deletedElements);
                                                                }
                                                                this.setState({ dados_banner: dados, dado_select: -1 });
                                                                this.containerRef.current.focus();
                                                            }
                                                            else if (e.key == 'ArrowUp') {
                                                                let dados = [...this.state.dados_banner];
                                                                if (dados[id].y - 5 > 0) {
                                                                    dados[id].y = dados[id].y - 5;
                                                                }
                                                                else {
                                                                    dados[id].y = dados[id].y = 0;
                                                                }
                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                this.setState({ dados_banner: dados });
                                                            }
                                                            else if (e.key == 'ArrowDown') {
                                                                let dados = [...this.state.dados_banner];
                                                                if (dados[id].y + 5 < 599 - dados[id].height) {
                                                                    dados[id].y = dados[id].y + 5;
                                                                }
                                                                else {
                                                                    dados[id].y = dados[id].y = 599 - dados[id].height;
                                                                }
                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                this.setState({ dados_banner: dados });
                                                            }
                                                            else if (e.key == 'ArrowLeft') {
                                                                let dados = [...this.state.dados_banner];
                                                                if (dados[id].x - 5 > 0) {
                                                                    dados[id].x = dados[id].x - 5;
                                                                }
                                                                else {
                                                                    dados[id].x = dados[id].x = 0;
                                                                }
                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                this.setState({ dados_banner: dados });
                                                            }
                                                            else if (e.key == 'ArrowRight') {
                                                                console.log(this.state.width);
                                                                let dados = [...this.state.dados_banner];
                                                                console.log(dados[id].x + 5);
                                                                if (dados[id].x + 5 < this.state.width - dados[id].width) {
                                                                    dados[id].x = dados[id].x + 5;
                                                                }
                                                                else {
                                                                    dados[id].x = dados[id].x = this.state.width - dados[id].width;
                                                                }
                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                this.setState({ dados_banner: dados });
                                                            }
                                                        }

                                                    }
                                                } : {}}
                                            >
                                                <div style={{ position: 'relative', width: this.state.width, height: 599, display: 'flex', justifyContent: 'center' }} ref={this.bannerRef}>
                                                    <div id="meio_x" hidden style={{ position: 'absolute', left: parseInt(this.state.width / 2), top: 0, height: 599, width: 1, borderRight: '2px dashed red' }}>

                                                    </div>
                                                    <div hidden id="meio_y" style={{ position: 'absolute', top: parseInt(600 / 2), height: 1, width: this.state.width, borderTop: '2px dashed red' }}>

                                                    </div>
                                                    <img ref={this.image_ref} hidden={this.state.loading_save} style={{ width: this.state.width, height: 599 }} />
                                                    <div onClick={(e) => {
                                                        // console.log(e.target);

                                                        // console.log(e.currentTarget);
                                                        // console.log(e.currentTarget.firstChild);

                                                        if ((e.target !== e.currentTarget) && (e.target != e.currentTarget.firstChild)) return;

                                                        this.setState({ dado_select: -1 });
                                                    }} tabIndex={73278} ref={this.containerRef} style={{ width: '100%', height: '100%', top: 0, bottom: 0, left: 0, right: 0, position: 'absolute' }} onKeyDown={(e) => {
                                                        // console.log(e.metaKey);
                                                        // console.log(e.ctrlKey);
                                                        // console.log(e.key);

                                                        if ((e.metaKey || e.ctrlKey) && e.key == 'z' && this.state.dados_banner.filter((item, id) => (
                                                            item.editable == true
                                                        )).length == 0) {
                                                            if (this.deletedElements.length > 0) {
                                                                console.log('element');
                                                                let dados = [...this.state.dados_banner];
                                                                if (this.deletedElements[0].status == 'delete') {
                                                                    dados.push({ ...this.deletedElements[0].element });
                                                                }
                                                                else if (this.deletedElements[0].status == 'add') {
                                                                    dados = dados.filter((item, i) => {
                                                                        if (item.key != this.deletedElements[0].element.key) {
                                                                            return true;
                                                                        }
                                                                    });

                                                                }
                                                                else if (this.deletedElements[0].status == 'change') {
                                                                    for (let i = 0; i < dados.length; i++) {
                                                                        if (dados[i].key == this.deletedElements[0].element.key) {
                                                                            dados[i] = this.deletedElements[0].element;
                                                                            dados[i].refInput.style.transform = "rotate(" + dados[i].rotate + "deg)";

                                                                            break;
                                                                        }
                                                                    }
                                                                }
                                                                this.setState({ dados_banner: dados });
                                                                this.deletedElements.shift();
                                                            }
                                                        }
                                                    }}>
                                                        <DragResizeContainer
                                                            className='resize-container'
                                                            resizeProps={{
                                                                minWidth: 10,
                                                                minHeight: 10,
                                                                enable: canResizable(),
                                                                onResizeStart: (e) => {
                                                                    let index = e.target.parentElement.firstChild.id.replace('id_', '');
                                                                    this.deletedElements.unshift({ element: { ...this.state.dados_banner[index] }, status: 'change' });

                                                                    this.setState({ dado_select: index });
                                                                }
                                                            }}

                                                            layout={this.state.dados_banner}
                                                            dragProps={{
                                                                disabled: this.state.dados_banner.filter((item, id) => (
                                                                    item.editable == true
                                                                )).length != 0,
                                                                onStart: (e) => {
                                                                    let index = e.target.parentElement.id.replace('id_', '');
                                                                    if (index == '' || index == null) {
                                                                        index = e.target.id.replace('id_', '');
                                                                    }
                                                                    this.elementDrag = { ...this.state.dados_banner[index] };
                                                                    this.indexDrag = index;
                                                                },
                                                                onStop: (e) => {
                                                                    console.log('stop');

                                                                    let index = this.indexDrag;
                                                                    console.log('Index:', index);
                                                                    let dados = this.state.dados_banner;
                                                                    console.log(dados[index]);

                                                                    let x = 0;
                                                                    let y = 0;
                                                                    let posicao_caixa = 0;
                                                                    if (dados[index] != undefined) {

                                                                        if (dados[index].refInput.parentElement.parentElement.parentElement.style.transform.split(',').length == 2) {

                                                                            x = dados[index].refInput.parentElement.parentElement.parentElement.style.transform.split(',')[0].replace('translate(', '').replace('px', '');
                                                                            y = dados[index].refInput.parentElement.parentElement.parentElement.style.transform.split(',')[1].replace('px)', '').replace(' ', '');
                                                                            let width = parseFloat(dados[index].refInput.parentElement.parentElement.parentElement.style.width.replace('px', ''));
                                                                            let height = parseFloat(dados[index].refInput.parentElement.parentElement.parentElement.style.height.replace('px', ''));

                                                                            posicao_caixa = (width / 2) + parseFloat(x);
                                                                            dados[index].x = parseInt(x);
                                                                            dados[index].y = parseInt(y);
                                                                            // console.log(parseInt(this.state.width/2));
                                                                            // console.log(y);
                                                                            let posicao_caixa_y = (height / 2) + parseFloat(y);
                                                                            if (parseInt(600 / 2) + 5 > parseInt(posicao_caixa_y) && parseInt(600 / 2) - 5 < parseInt(posicao_caixa_y)) {
                                                                                dados[index].y = (600 - height) / 2
                                                                                // console.log(dados[index]);
                                                                                document.getElementById('meio_y').hidden = false;
                                                                            }
                                                                            else {
                                                                                document.getElementById('meio_y').hidden = true;
                                                                            }

                                                                            if (parseInt(this.state.width / 2) + 5 > parseInt(posicao_caixa) && parseInt(this.state.width / 2) - 5 < parseInt(posicao_caixa)) {
                                                                                dados[index].x = (this.state.width - width) / 2
                                                                                document.getElementById('meio_x').hidden = false;
                                                                            }
                                                                            else {
                                                                                document.getElementById('meio_x').hidden = true;
                                                                            }
                                                                            dados[index].refInput.parentElement.parentElement.parentElement.style.transform = `translate(${dados[index].x}px,${dados[index].y}px)`;

                                                                        }
                                                                        if (this.elementDrag.x != this.state.dados_banner[index].x || this.elementDrag.y != this.state.dados_banner[index].y) {
                                                                            this.deletedElements.unshift({ element: { ...this.elementDrag }, status: 'change' });
                                                                        }
                                                                        this.elementDrag = null;
                                                                    }
                                                                },
                                                                onDrag: (e) => {
                                                                    return;
                                                                    // console.log(e.target.parentElement.parentElement.style.width.replace('px', ''));
                                                                    // console.log(e.target.parentElement.parentElement.parentElement);
                                                                    let dados = this.state.dados_banner;

                                                                    let index = e.target.parentElement.id.replace('id_', '');
                                                                    // console.log(e.target.parentElement.parentElement.parentElement.style.transform.split(','));
                                                                    let x = 0;
                                                                    let y = 0;
                                                                    let posicao_caixa = 0;

                                                                    if (e.target.parentElement.parentElement.parentElement.style.transform.split(',').length == 2) {

                                                                        x = e.target.parentElement.parentElement.parentElement.style.transform.split(',')[0].replace('translate(', '').replace('px', '');
                                                                        y = e.target.parentElement.parentElement.parentElement.style.transform.split(',')[1].replace('px)', '').replace(' ', '');
                                                                        let width = parseFloat(e.target.parentElement.parentElement.style.width.replace('px', ''));
                                                                        let height = parseFloat(e.target.parentElement.parentElement.style.height.replace('px', ''));

                                                                        posicao_caixa = (width / 2) + parseFloat(x);
                                                                        dados[index].x = parseInt(x);
                                                                        dados[index].y = parseInt(y);
                                                                        // console.log(parseInt(this.state.width/2));
                                                                        console.log(x);
                                                                        // console.log(y);
                                                                        let posicao_caixa_y = (height / 2) + parseFloat(y);
                                                                        console.log(posicao_caixa_y);
                                                                        if (parseInt(600 / 2) + 5 > parseInt(posicao_caixa_y) && parseInt(600 / 2) - 5 < parseInt(posicao_caixa_y)) {
                                                                            dados[index].y = (600 - height) / 2
                                                                            // console.log(dados[index]);
                                                                            document.getElementById('meio_y').hidden = false;
                                                                        }
                                                                        else {
                                                                            document.getElementById('meio_y').hidden = true;
                                                                        }

                                                                        if (parseInt(this.state.width / 2) + 5 > parseInt(posicao_caixa) && parseInt(this.state.width / 2) - 5 < parseInt(posicao_caixa)) {
                                                                            dados[index].x = (this.state.width - width) / 2
                                                                            document.getElementById('meio_x').hidden = false;
                                                                        }
                                                                        else {
                                                                            document.getElementById('meio_x').hidden = true;
                                                                        }
                                                                        e.target.parentElement.parentElement.parentElement.style.transform = `translate(${dados[index].x}px,${dados[index].y}px)`;

                                                                    }

                                                                }
                                                            }}
                                                            onLayoutChange={(e) => {
                                                                let dados_change = null;
                                                                for (let i = 0; i < e.length; i++) {
                                                                    if (e[i].x != this.state.dados_banner[i].x ||
                                                                        e[i].y != this.state.dados_banner[i].y ||
                                                                        e[i].width != this.state.dados_banner[i].width ||
                                                                        e[i].height != this.state.dados_banner[i].yheight
                                                                    ) {
                                                                        dados_change = e[i];
                                                                        break;
                                                                    }
                                                                }
                                                                // console.log(dados_change);
                                                                this.setState({ dados_banner: e })
                                                            }}
                                                            scale={true}
                                                        >
                                                            {this.state.dados_banner.map((single, id) => {
                                                                return (
                                                                    <div id={'id_' + id} ref={single.ref} tabIndex={id} onMouseUp={() => {
                                                                        document.getElementById('meio_x').hidden = true;
                                                                        document.getElementById('meio_y').hidden = true;
                                                                        this.click = false;

                                                                    }} onKeyDown={(e) => {
                                                                        if (single.editable == false) {

                                                                            e.preventDefault();
                                                                            // console.log(e.metaKey);
                                                                            // console.log(e.key);
                                                                            if ((e.metaKey || e.ctrlKey) && e.key == 'c') {
                                                                                this.copy_element = { ...single };
                                                                            }

                                                                            else if ((e.metaKey || e.ctrlKey) && e.key == 'v') {
                                                                                if (this.copy_element != null) {
                                                                                    let dados = [...this.state.dados_banner];
                                                                                    if (this.copy_element.y + 10 < 599) {
                                                                                        this.copy_element.y += 10;
                                                                                    }
                                                                                    else if (this.copy_element.y - 10 > 0) {
                                                                                        this.copy_element.y -= 10;
                                                                                    }
                                                                                    this.copy_element.key = uuidv4();
                                                                                    dados.push({ ...this.copy_element });
                                                                                    this.setState({ dados_banner: dados });
                                                                                    this.deletedElements.unshift({ element: { ...this.copy_element }, status: 'add' });

                                                                                }

                                                                            }
                                                                            else if (e.key == 'Delete') {
                                                                                let delete_ = null;

                                                                                let dados = this.state.dados_banner.filter((item, i) => {
                                                                                    if (i != id) {
                                                                                        return true;
                                                                                    }
                                                                                    else {
                                                                                        delete_ = item;
                                                                                    }
                                                                                });
                                                                                if (delete_ != null) {
                                                                                    this.deletedElements.unshift({ element: { ...delete_ }, status: 'delete' });
                                                                                    console.log(this.deletedElements);
                                                                                }
                                                                                this.setState({ dados_banner: dados, dado_select: -1 });
                                                                                this.containerRef.current.focus();

                                                                            }
                                                                            else if (e.key == 'ArrowUp') {
                                                                                let dados = [...this.state.dados_banner];
                                                                                if (dados[id].y - 5 > 0) {
                                                                                    dados[id].y = dados[id].y - 5;
                                                                                }
                                                                                else {
                                                                                    dados[id].y = dados[id].y = 0;
                                                                                }
                                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                                this.setState({ dados_banner: dados });
                                                                            }
                                                                            else if (e.key == 'ArrowDown') {
                                                                                let dados = [...this.state.dados_banner];
                                                                                if (dados[id].y + 5 < 599 - dados[id].height) {
                                                                                    dados[id].y = dados[id].y + 5;
                                                                                }
                                                                                else {
                                                                                    dados[id].y = dados[id].y = 599 - dados[id].height;
                                                                                }
                                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                                this.setState({ dados_banner: dados });
                                                                            }
                                                                            else if (e.key == 'ArrowLeft') {
                                                                                let dados = [...this.state.dados_banner];
                                                                                if (dados[id].x - 5 > 0) {
                                                                                    dados[id].x = dados[id].x - 5;
                                                                                }
                                                                                else {
                                                                                    dados[id].x = dados[id].x = 0;
                                                                                }
                                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                                this.setState({ dados_banner: dados });
                                                                            }
                                                                            else if (e.key == 'ArrowRight') {
                                                                                console.log(this.state.width);
                                                                                let dados = [...this.state.dados_banner];
                                                                                console.log(dados[id].x + 5);
                                                                                if (dados[id].x + 5 < this.state.width - dados[id].width) {
                                                                                    dados[id].x = dados[id].x + 5;
                                                                                }
                                                                                else {
                                                                                    dados[id].x = dados[id].x = this.state.width - dados[id].width;
                                                                                }
                                                                                this.deletedElements.unshift({ element: { ...dados[id] }, status: 'change' });

                                                                                this.setState({ dados_banner: dados });
                                                                            }
                                                                        }
                                                                        else if(e.key=='Enter'){
                                                                            e.preventDefault();
                                                                        }

                                                                    }} onMouseDown={(e) => {
                                                                        console.log(id);

                                                                        this.setState({ dado_select: id });
                                                                    }}
                                                                        onClick={() => {

                                                                        }}
                                                                        onCopy={(e) => {
                                                                            if (single.editable == false) {

                                                                                this.copy_element = { ...single };
                                                                            }
                                                                        }}
                                                                        onPaste={(e) => {
                                                                            if (single.editable == false) {

                                                                                if (this.copy_element != null) {
                                                                                    let dados = [...this.state.dados_banner];
                                                                                    if (this.copy_element.y + 10 < 599) {
                                                                                        this.copy_element.y += 10;
                                                                                    }
                                                                                    else if (this.copy_element.y - 10 > 0) {
                                                                                        this.copy_element.y -= 10;
                                                                                    }
                                                                                    this.copy_element.key = uuidv4();
                                                                                    dados.push({ ...this.copy_element });
                                                                                    this.setState({ dados_banner: dados });
                                                                                    this.deletedElements.unshift({ element: { ...this.copy_element }, status: 'add' });

                                                                                }
                                                                            }
                                                                        }}
                                                                        onBlur={() => {
                                                                            // let dados = [...this.state.dados_banner];
                                                                            // dados[id].editable = false;
                                                                            // console.log('blur');
                                                                            // this.setState({ dados_banner: dados });
                                                                        }}
                                                                        onDoubleClick={() => {

                                                                        }} key={single.key} style={{ outline: 'none', cursor: 'grab', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', border: this.state.dado_select == id ? '1px solid blue' : 'none', zIndex: 10 }} className='child-container size-auto'>
                                                                        {/* <textarea style={{ background: 'transparent', height: '100%', width: '100%', border: 0 }} /> */}
                                                                        {<div onDoubleClick={() => {
                                                                            console.log(single.refInput);
                                                                            let dados = [...this.state.dados_banner];
                                                                            dados[id].editable = true;
                                                                            this.setState({ dados_banner: dados });
                                                                            setTimeout(() => {
                                                                                single.refInput.focus();
                                                                            }, 100);
                                                                        }} onBlur={() => {
                                                                            let dados = [...this.state.dados_banner];
                                                                            dados[id].editable = false;
                                                                            dados[id].value = single.refInput.innerHTML;

                                                                            this.setState({ dados_banner: dados });
                                                                        }} 
                                                                        onPaste={(e)=>{
                                                                            e.preventDefault()
                                                                            var text = e.clipboardData.getData('text/plain');
                                                                            document.execCommand('insertText', false, text)
                                                                        }}
                                                                        onKeyUp={(e) => {
                                                                            // console.log(single.refInput.innerHTML);
                                                                            // let dados = [...this.state.dados_banner];
                                                                            // dados[id].value = single.refInput.innerHTML;
                                                                            // this.setState({ dados_banner: dados });
                                                                        }} ref={(ref) => single.refInput = ref} contentEditable={single.editable} dangerouslySetInnerHTML={{ __html: single.value }} value={single.value} className="texto_banner" style={{
                                                                            width: '100%',
                                                                            height: single.height,
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            // overflow:'hidden',
                                                                            // cursor:'text',
                                                                            // height: '100%',
                                                                            // cursor:single.editable?'text':'',
                                                                            backgroundColor: 'transparent',
                                                                            fontSize: parseInt(single.height / 1.5), fontFamily: single.fontFamily,
                                                                            color: single.color,
                                                                            textAlign: single.textAlign,
                                                                            border: this.state.dado_select == id ? '1px dashed grey' : 'none',
                                                                            overflow: 'hidden',
                                                                            // lineHeight: parseInt(single.height / 1.5)+'px',
                                                                            // borderLeft:this.state.dado_select == id ?'1px solid lightgrey':'',
                                                                            // borderRight:this.state.dado_select == id ?'1px solid lightgrey':'',

                                                                            // background: 'transparent',
                                                                            resize: 'none',
                                                                            // margin:20
                                                                        }}>
                                                                        </div>}
                                                                    </div>
                                                                );
                                                            })}
                                                        </DragResizeContainer>
                                                    </div>

                                                </div>
                                                {/* <JoditEditor
                                                    value={this.state.text}
                                                    config={{
                                                        "autofocus": true,
                                                        "useSearch": false,
                                                        "spellcheck": false,
                                                        "language": "pt_br",
                                                        "allowResizeY": false,
                                                        "showCharsCounter": false,
                                                        "showWordsCounter": false,
                                                        "showXPathInStatusbar": false,
                                                        "buttons": "align,undo,redo"
                                                    }}
                                                    tabIndex={0} // tabIndex of textarea
                                                    onChange={newContent => { }}
                                                /> */}
                                                {this.state.dado_select != -1 && <div style={{ position: 'relative' }}> <div style={{ height: 200, width: 200, position: 'absolute', left: 50 }}>
                                                    <div style={{
                                                        top: "-110px",
                                                        position: 'absolute',
                                                        color: 'black',
                                                        right: 0, left: 0
                                                    }}>
                                                        <h3 style={{ textAlign: "center" }}>Rotacione o texto</h3>
                                                    </div>
                                                    <div
                                                        onMouseDown={() => {
                                                            this.click = true;
                                                        }}
                                                        onMouseUp={() => {
                                                            this.click = false;
                                                            let dados = [...this.state.dados_banner];
                                                            if (dados[this.state.dado_select].rotate != this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '')) {
                                                                this.deletedElements.unshift({ element: { ...dados[this.state.dado_select] }, status: 'change' });
                                                            }
                                                            dados[this.state.dado_select].rotate = this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '');

                                                            this.setState({ dados_banner: dados });
                                                        }}

                                                        onMouseMove={(e) => {
                                                            // console.log(e.nativeEvent.offsetX);
                                                            if (this.click == true) {

                                                                let x = e.nativeEvent.layerX - 100;
                                                                let y = e.nativeEvent.layerY - 100;
                                                                y = y > 0 ? -y : Math.abs(y);
                                                                let anglo = Math.atan2(y, x) * (180 / Math.PI);
                                                                if (anglo < 0) {
                                                                    anglo = 180 + (180 + (Math.atan2(y, x) * (180 / Math.PI)));
                                                                }
                                                                anglo = 360 - anglo;
                                                                anglo = Math.ceil(anglo / 5) * 5;
                                                                this.refRotate.current.style.transform = "rotate(" + anglo + "deg)";
                                                                // if(this.state.dados_banne)

                                                                if (this.state.dado_select != -1 && this.state.dado_select != undefined) {
                                                                    this.state.dados_banner[this.state.dado_select].refInput.style.transform = "rotate(" + anglo + "deg)";
                                                                }
                                                            }

                                                        }} style={{ height: 200, width: 200, border: '2px solid grey', position: 'absolute', borderRadius: 1000 }}>


                                                    </div>
                                                    <div onMouseDown={() => {
                                                        this.click = true;
                                                    }} onMouseUp={() => {
                                                        this.click = false;
                                                        let dados = [...this.state.dados_banner];
                                                        if (dados[this.state.dado_select].rotate != this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '')) {
                                                            this.deletedElements.unshift({ element: { ...dados[this.state.dado_select] }, status: 'change' });
                                                        }
                                                        dados[this.state.dado_select].rotate = this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '');

                                                        this.setState({ dados_banner: dados });
                                                    }} style={{ position: 'absolute', left: 0, right: 0, top: 100, border: '1px dashed lightgrey' }}>

                                                    </div>
                                                    <div onMouseDown={() => {
                                                        this.click = true;
                                                    }} onMouseUp={() => {
                                                        this.click = false;
                                                        let dados = [...this.state.dados_banner];
                                                        if (dados[this.state.dado_select].rotate != this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '')) {
                                                            this.deletedElements.unshift({ element: { ...dados[this.state.dado_select] }, status: 'change' });
                                                        }
                                                        dados[this.state.dado_select].rotate = this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '');

                                                        this.setState({ dados_banner: dados });
                                                    }} style={{ position: 'absolute', top: 0, bottom: 0, left: 100, border: '1px dashed lightgrey' }}>

                                                    </div>
                                                    <div onMouseDown={() => {
                                                        this.click = true;
                                                    }} ref={this.refRotate} onMouseUp={() => {
                                                        this.click = false;
                                                        let dados = [...this.state.dados_banner];
                                                        if (dados[this.state.dado_select].rotate != this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '')) {
                                                            this.deletedElements.unshift({ element: { ...dados[this.state.dado_select] }, status: 'change' });
                                                        }
                                                        dados[this.state.dado_select].rotate = this.refRotate.current.style.transform.replace('rotate(', '').replace('deg)', '');

                                                        this.setState({ dados_banner: dados });
                                                    }} style={{ position: 'absolute', left: 0, right: 0, top: 100, border: '1px solid red', transform: 'rotate(' + this.state.dados_banner[this.state.dado_select].rotate + 'deg)' }}>
                                                        <i style={{
                                                            position: 'absolute', right: '74px',
                                                            fontSize: '3rem',
                                                            color: 'black',
                                                            top: '-35px'
                                                        }} className="mdi mdi-rotate-left"></i>
                                                    </div>
                                                    <div style={{
                                                        right: "-25px",
                                                        top: "90px",
                                                        position: 'absolute',
                                                        color: 'black'
                                                    }}>
                                                        0°
                                                        </div>
                                                    <div style={{
                                                        left: "-30px",
                                                        top: "90px",
                                                        position: 'absolute',
                                                        color: 'black'
                                                    }}>
                                                        180°
                                                        </div>
                                                    <div style={{
                                                        top: "-25px",
                                                        position: 'absolute',
                                                        color: 'black',
                                                        right: '90px'
                                                    }}>
                                                        90°
                                                        </div>
                                                    <div style={{
                                                        bottom: "-25px",
                                                        position: 'absolute',
                                                        color: 'black',
                                                        right: '80px'
                                                    }}>
                                                        270°
                                                        </div>

                                                </div></div>}
                                            </div>

                                            <div className="col-12 mt-3">
                                                {this.state.loading_save == false && <div className="row">
                                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                                        style={{ marginBottom: '0.5rem' }}>
                                                        <Link to={"/dados/3"}> <span
                                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                                Voltar</i></span></Link>
                                                    </div>
                                                    <div hidden={this.state.modelo_id == ''} className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                                        style={{ marginBottom: '0.5rem' }}>
                                                        <button type="button" onClick={() => this.gerar_imagem()} className="btn btn-success btn-lg waves-effect waves-light"
                                                            style={{ backgorundColor: '#f2f2f2' }}>Gerar banner</button>
                                                        <Link id="gerar" target="_blank" hidden to={this.state.path}></Link>

                                                    </div>
                                                </div>}
                                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                                    <div className="spinner-border text-primary" role="status"  >
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div></div>}
                                            </div>
                                        </div>



                                    </div>

                                </div>



                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
                <Modal show={this.state.lista_modal} onHide={() => {
                    this.setState({ lista_modal: false, folha_id: '' })
                }} >
                    <Modal.Header closeButton><Modal.Title>Adicionar à uma lista de impressão</Modal.Title></Modal.Header>
                    <Modal.Body>
                        <div className="form-group row">
                            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                <p style={{ color: 'red' }}>{this.state.msgErroLista}</p>
                            </div>
                            <label className="col-sm-12 col-form-label">Listas</label>
                            <div className="col-sm-12">
                                <select value={this.state.lista_id} className="custom-select" id="cameras" onChange={(e) => {
                                    if (e.target.value == '' || e.target.value == 'add') {
                                        this.setState({ lista_id: e.target.value });
                                    }
                                    else {
                                        let lista_s = {}
                                        let listas_selecionadas = { ...this.state.listas_selecionadas };
                                        if (!Object.keys(listas_selecionadas).includes(e.target.value)) {

                                            for (let i = 0; i < this.state.listas.length; i++) {
                                                if (this.state.listas[i].id == e.target.value) {
                                                    lista_s = this.state.listas[i];
                                                }
                                            }
                                            listas_selecionadas[lista_s.id] = lista_s;
                                        }

                                        this.setState({ lista_id: '', listas_selecionadas });

                                    }
                                }}>
                                    <option value={''}>Selecione uma ou mais listas de impressao</option>
                                    <option value={'add'}>Adiciona uma nova lista</option>

                                    {this.state.listas.map((item, id) => (
                                        <option key={id} value={item.id}>{item.name} - {item.folha}</option>
                                    ))}
                                </select>
                            </div>

                            {Object.values(this.state.listas_selecionadas).length != 0 && this.state.lista_id != 'add' && <div className="col-sm-12"> <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Listas Selecionados:</label>

                                {Object.values(this.state.listas_selecionadas).map((item, id) => (
                                    <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                        padding: 10, display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <span>{item.name}</span>
                                        <button onClick={() => {
                                            let users = { ...this.state.listas_selecionadas };
                                            delete (users[item.id]);
                                            this.setState({ listas_selecionadas: users });
                                        }}
                                            className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                className="ti-trash" style={{ color: 'red' }}></i></button>
                                    </div>
                                ))}

                            </div>
                            </div>}
                        </div>
                        {this.state.lista_id == 'add' && <div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Nome</label>
                                <div className="col-sm-12">
                                    <input value={this.state.name_lista} className="form-control" placeholder="Nome da lista" type="text" onChange={(e) => this.setState({ name_lista: e.target.value })} />

                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Folha</label>
                                <div className="col-sm-12">
                                    <select value={this.state.folha_id} className="custom-select" id="cameras" onChange={this.change_folha.bind(this)}>
                                        <option value={''}>Selecione um tamanho de folha</option>

                                        {this.props.folhas.map((item, id) => (
                                            <option key={id} value={id}>{item}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Link target="_blank" to={'/banner_pdf_cliente/' + this.state.banner_id}> <span type="button" className="btn btn-success btn-lg waves-effect waves-light"
                            style={{ backgorundColor: '#f2f2f2' }}>Imprimir Banner</span>
                        </Link>
                        {this.state.loading_lista == false && <Button className="btn-success btn-lg" color="success" onClick={() => {

                            if (this.state.lista_id == 'add') {
                                this.salvar_lista(this.state.banner_id);
                            }
                            else if (Object.keys(this.state.listas_selecionadas).length != 0) {
                                this.add_lista_existente(this.state.banner_id);
                            }
                            else {
                                this.setState({ msgErroLista: 'Selecione uma ou mais listas de impressão' });
                            }
                        }}>Adicionar à lista</Button>}{' '}
                        {this.state.loading_lista == false && <Button className="btn-lg" color="secondary" onClick={() => {
                            this.setState({ lista_modal: false, folha_id: '' })
                        }}>Fechar</Button>}
                        {this.state.loading_lista == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div></div>}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    add_lista_existente(banner_id) {
        this.setState({ loading_lista: true, msgErroLista: '' });
        fetch(`${URL}api/cliente/add_lista_existente`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                listas_selecionadas: Object.keys(this.state.listas_selecionadas),
                banners_selecionados: [banner_id],
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_lista: false, msgErroLista: erro });
                }
                else {

                    this.setState({ loading_lista: false, lista_modal: false, folha_id: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_lista: false, msgErroLista: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_lista: false, msgErroLista: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    salvar_lista(banner_id) {
        this.setState({ loading_lista: true, msgErroLista: '' });
        fetch(`${URL}api/cliente/salvar_lista`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name_lista,
                folha: this.props.folhas[this.state.folha_id] != undefined ? this.props.folhas[this.state.folha_id] : '',

                banners_selecionados: [banner_id],
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_lista: false, msgErroLista: erro });
                }
                else {

                    this.setState({ loading_lista: false, lista_modal: false, folha_id: '' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_lista: false, msgErroLista: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_lista: false, msgErroLista: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        loja: state.ClienteReducer.loja,
        modelos: state.ClienteReducer.modelos,
        folhas: state.ClienteReducer.folhas,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(ModelBanner);


