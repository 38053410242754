
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { logout, mudarUser} from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../assets/images/users/user-4.jpg';
import moment from 'moment';
import { URL } from '../variables';

class ProcessoUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            nao_iniciado: false,
            regras_processo: false,
            andamento: false,
            finalizada: false,
            loading_screen: true
        };
    }

    solicitar_camera() {
        // this.setState({ loading_camera: true, msg: '', show_solicitar: false });
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(() => this.get_camera()).catch(this.handleError.bind(this));
    }

    async get_camera() {

        var devices = await navigator.mediaDevices.enumerateDevices();
        let device_local = localStorage.getItem("deviceId")
        console.log(devices);
        let exists = false;
        let devices_camera = [];
        let devices_mic = [];
        var _stream = null;
        let devices_cam_id = []
        let devices_mic_id = []

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].kind == 'videoinput') {
                if (!devices_cam_id.includes(devices[i].deviceId)) {
                    devices_camera.push(devices[i]);
                    devices_cam_id.push(devices[i].deviceId);

                }
                if (device_local == devices[i].deviceId) {
                    exists = true;
                }
            }
            else if (devices[i].kind == 'audioinput') {
                if (!devices_mic_id.includes(devices[i].deviceId)) {
                    devices_mic.push(devices[i]);
                    devices_mic_id.push(devices[i].deviceId);

                }
            }

        }
        console.log(devices_camera);
        console.log('oi');
        if (devices_camera.length > 0 && devices_mic.length > 0) {
            let use = '';
            if (exists == true) {
                use = device_local;
            }
            else {
                localStorage.setItem("deviceId", devices_camera[0]);
                use = devices_camera[0];
            }
            console.log(use)
            this.props.mudarDevices({ devices_camera: devices_camera, devices_mic, device_select: use })
            navigator.mediaDevices.getUserMedia({ video: { deviceId: use }, audio: true }).
                then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
        }
        else {
            this.setState({ show_solicitar: true, loading_camera: false, msg: 'Não foi reconhecido nenhuma câmera ou microfone conectado em seu computador. Conecte e tente novamente' })
        }
    }

    handleSuccess(stream) {
        this.props.mudarStream(stream);
        this.get_processo();

        // screenshotButton.disabled = false;
        // window.location="/processos_user"

        // this.setState({ show_aceitar: true, loading_camera: false, show_video: true,stream:stream });
        // this.videoRef.current.style.display = 'flex';

        // this.videoRef.current.srcObject = stream;
        // this.videoRef.current.volume = 0;
    }

    handleError(stream) {
        console.log(stream);
        this.setState({ redirect: true });

    }

    componentDidMount() {
        console.log('oi');
        if (this.props.stream == null) {
            this.setState({ loading_screen: true });

            this.solicitar_camera();
        }
        else {
            this.get_processo();
        }
    }

    get_processo() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_processo/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    this.props.mudarUser(resp.user);
                    // if (resp.processo != null) {
                    this.props.mudarProcesso(resp.processo);
                    // }
                    // if (resp.sala != null) {
                    this.props.mudarSala(resp.sala);
                    this.props.mudarTempoAtual(resp.tempo_atual);

                    // }
                    if (resp.nao_iniciado == true) {
                        this.setState({ loading_screen: false, nao_iniciado: true, regras_processo: false, andamento: false, finalizada: false });
                    }
                    else if (resp.regras_processo == true) {
                        this.setState({ loading_screen: false, nao_iniciado: false, regras_processo: true, andamento: false, finalizada: false });
                    }
                    else if (resp.andamento == true) {
                        this.setState({ loading_screen: false, nao_iniciado: false, regras_processo: false, andamento: true, finalizada: false });
                        this.props.mudarRedacoes(resp.redacoes);
                        this.props.mudarProvas(resp.provas);
                        this.props.mudarCategorias(resp.categorias);
                        this.props.mudarMensagens(resp.mensagens);

                    }

                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    aceitar_regras(resp) {
        this.setState({ nao_iniciado: false, regras_processo: false, andamento: true, finalizada: false });
        this.props.mudarUser(resp.user);
        this.props.mudarProcesso(resp.processo);
        this.props.mudarSala(resp.sala);
        this.props.mudarTempoAtual(resp.tempo_atual);
        this.props.mudarRedacoes(resp.redacoes);
        this.props.mudarProvas(resp.provas);
        this.props.mudarCategorias(resp.categorias);
        this.props.mudarMensagens(resp.mensagens);
    }





    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.processo.name}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Nome do candidato: {this.props.user.name}</p>


                            <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de início: </b>
                                {moment(this.props.processo.inicio).format('DD/MM/YYYY HH:mm')}
                            </p>

                            <p className="m-b-20" style={{ color: 'black', marginBottom: '0.4rem' }}><b>Hora de encerramento: </b>
                                {moment(this.props.processo.fim).format('DD/MM/YYYY HH:mm')}
                            </p>
                            <hr />
                            {this.state.loading_screen == false && <div>

                                <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', fontSize: '1rem' }}><b style={{ fontWeight: 'bold' }}>Descrição da avaliação: </b>
                                    {"\n"}
                                    {this.props.processo.descricao}
                                </p>

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout,  mudarUser,  })(ProcessoUser);


