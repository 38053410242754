
import React, { Component } from 'react';

import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout, mudarUser, mudarTitle } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';

class CriarLista extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/dados/4',
            loading_screen: true,
            name: '',
            loading_save: false,
            msgErro: '',
            loja: {},
            folhas:[],
            folha_id:'',
            banner_name: '',
            show_autocomplete_banner: false,
            banners: [],
            banners_selecionados: {},
            loading_search_banner: false,
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/cliente/salvar_lista`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                
                folha: this.state.folhas[this.state.folha_id],

                banners_selecionados: Object.keys(this.state.banners_selecionados),
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,  redirect: true, path: '/dados/4'});

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.props.mudarTitle('Listas de Impressão');

        this.get_dados_lista(this.props.match.params.id);
    }

    get_dados_lista() {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/cliente/get_dados_lista`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_screen: false, loja: resp.loja, folhas: resp.folhas });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



   
    select_banner(item) {
        let users = { ...this.state.banners_selecionados };
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ banner_name: '', show_autocomplete_banner: false, banners_selecionados: users })
    }

   

    get_banners_search(search) {

        if (this.state.loading_search_banner == false) {
            this.setState({ loading_search_banner: true });
        }
        fetch(`${URL}api/cliente/banners_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_banner: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_banner: false, banners: resp.banners });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_banner: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_banner: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    change_folha(event) {
        // let ratio_h = 600 / this.state.folhas[this.state.folha_id].height;
        // this.width = this.state.folhas[this.state.folha_id].width * ratio_h;
        // this.setState({ folha_id: event.target.value, width: this.state.folhas[this.state.folha_id].width * ratio_h });
        this.setState({ folha_id: event.target.value });

    }


    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Adicionar lista de impressão </h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Adicione uma lista de impressão para sua loja</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>

                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">

                                    <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome</label>
                                            <div className="col-sm-12">
                                                <input value={this.state.name} className="form-control" placeholder="Nome da lista" type="text" onChange={(e) => this.setState({ name: e.target.value })} />

                                            </div>
                                        </div>

                                       <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Folha</label>
                                            <div className="col-sm-12">
                                                <select value={this.state.folha_id} className="custom-select" id="cameras" onChange={this.change_folha.bind(this)}>
                                                <option  value={''}>Selecione um tamanho de folha</option>

                                                    {this.state.folhas.map((item, id) => (
                                                        <option key={id} value={id}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                       
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Adicione um ou mais banners a lista</label>
                                            <div className="col-sm-12">
                                                <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_banner: true })} className="form-control" value={this.state.banner_name} placeholder="Nome do banner" type="text" onChange={(e) => { this.get_banners_search(e.target.value); this.setState({ banner_name: e.target.value }) }} />
                                                {this.state.show_autocomplete_banner == true && this.state.banner_name != '' && <div style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto', overflow: 'scroll' }}>
                                                    {this.state.loading_search_banner == false && this.state.banners.map((item, id) => (
                                                        <div key={id} onClick={() => this.select_banner(item)} className="opcao_autocomplete">
                                                            {item.name}
                                                        </div>
                                                    ))}
                                                    {this.state.loading_search_banner == false && this.state.banners.length == 0 &&
                                                        <div className="opcao_autocomplete">
                                                            Nenhum banner encontrado com este termo
                                                        </div>
                                                    }
                                                    {this.state.loading_search_banner == true &&
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <div className="spinner-border text-primary" role="status"  >
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                </div>}
                                            </div>


                                        </div>
                                        {Object.values(this.state.banners_selecionados).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Banners Selecionados:</label>

                                            {Object.values(this.state.banners_selecionados).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    padding: 10, display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <span>{item.name}</span>
                                                    <button onClick={() => {
                                                        let users = { ...this.state.banners_selecionados };
                                                        delete (users[item.id]);
                                                        this.setState({ banners_selecionados: users });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                                            className="ti-trash" style={{ color: 'red' }}></i></button>
                                                </div>
                                            ))}

                                        </div>}
                                        <div className="row mb-3 mt-3">
                                            <div className="col-12 col-sm-12 d-flex justify-content-end"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <button type="button" onClick={() => this.setState({banners_selecionados:{}})} className="btn btn-danger waves-effect waves-light"
                                                    style={{ backgorundColor: '#f2f2f2' }}>Limpar Lista</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to={"/dados/4"}> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><i className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                Voltar</i></span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarUser, mudarTitle })(CriarLista);


